import { MutableRefObject, ReactNode } from 'react';
import { FieldError, UseControllerProps } from 'react-hook-form';

import dayjs, { Dayjs } from 'dayjs';

import { ModifiedMuiDatePickerProps } from 'src/components/RHF/DateField/DateField';
import { ReactHookFormValue } from 'src/components/RHF/DateField/useDateField';
import {
  BACKEND_DATE_FORMAT,
  DATE_SEPARATOR,
  DEFAULT_VALUE,
  LONG_DATE_FORMAT,
} from 'src/components/RHF/DateField/utilities/constants';
import { isString } from 'src/utilities/helperFunctions2';
import { useDateFormat } from 'src/utilities/hooks';

type ReactHookFormError = FieldError | undefined;

export function buildDatePickerProps(
  anchorReference: MutableRefObject<null>,
  shouldShowHelperText: boolean,
  reactHookFormError: ReactHookFormError,
  useControllerProps: UseControllerProps,
  muiDatePickerProps: ModifiedMuiDatePickerProps,
): ModifiedMuiDatePickerProps {
  return {
    ...muiDatePickerProps,
    closeOnSelect: muiDatePickerProps.closeOnSelect ?? false,
    slotProps: {
      ...muiDatePickerProps.slotProps,
      field: {
        ...muiDatePickerProps.slotProps?.field,
        clearable:
          muiDatePickerProps.slotProps?.field &&
          'clearable' in muiDatePickerProps.slotProps.field &&
          muiDatePickerProps.slotProps.field.clearable === false
            ? muiDatePickerProps.slotProps.field.clearable
            : true,
      },
      popper: {
        ...muiDatePickerProps.slotProps?.popper,
        anchorEl: anchorReference.current,
      },
      textField: {
        ...muiDatePickerProps.slotProps?.textField,
        error:
          muiDatePickerProps.slotProps?.textField &&
          'error' in muiDatePickerProps.slotProps.textField &&
          muiDatePickerProps.slotProps.textField.error
            ? muiDatePickerProps.slotProps.textField.error
            : !!reactHookFormError,
        helperText: computeHelperText(
          shouldShowHelperText,
          muiDatePickerProps.slotProps?.textField &&
            'helperText' in muiDatePickerProps.slotProps.textField &&
            muiDatePickerProps.slotProps.textField.helperText
            ? muiDatePickerProps.slotProps.textField.helperText
            : reactHookFormError?.message,
        ),
        required: !!useControllerProps.rules?.required,
        size:
          muiDatePickerProps.slotProps?.textField &&
          'size' in muiDatePickerProps.slotProps.textField &&
          muiDatePickerProps.slotProps.textField.size
            ? muiDatePickerProps.slotProps.textField.size
            : 'small',
        sx: {
          ...(muiDatePickerProps.slotProps &&
            muiDatePickerProps.slotProps.textField &&
            'sx' in muiDatePickerProps.slotProps.textField &&
            muiDatePickerProps.slotProps.textField.sx),
          svg: {
            ...(muiDatePickerProps.slotProps &&
              muiDatePickerProps.slotProps.textField &&
              'sx' in muiDatePickerProps.slotProps.textField &&
              muiDatePickerProps.slotProps.textField.sx &&
              'svg' in muiDatePickerProps.slotProps.textField.sx &&
              muiDatePickerProps.slotProps.textField.sx.svg &&
              typeof muiDatePickerProps.slotProps.textField.sx.svg === 'object' &&
              muiDatePickerProps.slotProps.textField.sx.svg),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //   @ts-ignore
            color:
              muiDatePickerProps.slotProps &&
              muiDatePickerProps.slotProps.textField &&
              'sx' in muiDatePickerProps.slotProps.textField &&
              muiDatePickerProps.slotProps.textField.sx &&
              'svg' in muiDatePickerProps.slotProps.textField.sx &&
              muiDatePickerProps.slotProps.textField.sx.svg &&
              typeof muiDatePickerProps.slotProps.textField.sx.svg === 'object' &&
              muiDatePickerProps.slotProps.textField.sx.svg &&
              'color' in muiDatePickerProps.slotProps.textField.sx.svg &&
              muiDatePickerProps.slotProps.textField.sx.svg.color
                ? muiDatePickerProps.slotProps.textField.sx.svg.color
                : 'primary.main',
          },
        },
      },
    },
  };
}

function computeHelperText(shouldShowHelperText: boolean, helperText?: ReactNode) {
  if (!shouldShowHelperText) return undefined;

  if (helperText) return helperText;

  // https://mui.com/material-ui/react-text-field/#helper-text

  return ' ';
}

export function computeNewValue(
  previousValue: ReactHookFormValue,
  incomingValue: Dayjs | null,
  isRange: boolean,
) {
  const formattedValue = incomingValue ? incomingValue.format(BACKEND_DATE_FORMAT) : DEFAULT_VALUE;
  const startingDate = previousValue?.[0];
  const endingDate = previousValue?.[1];

  const shouldReturnSingleDate =
    !formattedValue || !isRange || incomingValue?.isSame(startingDate, 'day');
  const shouldReturnFullDateRange =
    startingDate && !endingDate && incomingValue?.isAfter(startingDate, 'day');

  if (shouldReturnSingleDate) return formattedValue;

  if (shouldReturnFullDateRange) return [startingDate, formattedValue];

  return [formattedValue];
}

export function isDaySelected(day: Dayjs, value: ReactHookFormValue) {
  if (!value) return false;

  if (isString(value)) return day.isSame(value, 'day');

  const startingDateValue = dayjs(value[0], BACKEND_DATE_FORMAT);
  const endingDateValue = dayjs(value[1], BACKEND_DATE_FORMAT);

  return (
    day.isSame(startingDateValue, 'day') ||
    day.isSame(endingDateValue, 'day') ||
    (day.isAfter(startingDateValue, 'day') && day.isBefore(endingDateValue, 'day'))
  );
}

export function isZeroFormatDate(value: string) {
  return ['0000-00-00', '0000-00-00 00:00:00'].includes(value);
}

export function stringify(value: ReactHookFormValue) {
  const { formatDate } = useDateFormat();

  if (Array.isArray(value)) {
    return value.map((value) => formatDate(LONG_DATE_FORMAT, value)).join(DATE_SEPARATOR);
  }

  if (!value || !value.length || isZeroFormatDate(value)) return DEFAULT_VALUE;

  return formatDate(LONG_DATE_FORMAT, value);
}

export function getDateFormat(date: Dayjs | Date, languageCode: string): string {
  const normalizedDate = isDayjs(date) ? date.toDate() : date;

  return new Intl.DateTimeFormat(languageCode).format(normalizedDate);
}

function isDayjs(date: any): date is Dayjs {
  return (
    typeof date === 'object' &&
    date !== null &&
    'toDate' in date &&
    typeof date.toDate === 'function'
  );
}
