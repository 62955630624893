import i18next from 'i18next';

import { IntegrateUpdateResponse } from 'src/features/JobForm/JobForm.service';
import { FieldsTransformed, FieldValue } from 'src/pages/Job/Job.service';

export function getFilterSameValues(data: IntegrateUpdateResponse) {
  const integrateDifferences: Record<string, FieldValue> = {};
  const jobDifferences: Record<string, FieldValue> = {};

  for (const key in data.integrate) {
    if (data.integrate[key] !== data.job[key]) {
      integrateDifferences[key] = data.integrate[key];
      jobDifferences[key] = data.job[key];
    }
  }

  return {
    integrate: integrateDifferences,
    job: jobDifferences,
  };
}

export function getFilterSearchTerm(
  data: IntegrateUpdateResponse,
  jobFields: FieldsTransformed,
  searchTerm: string,
) {
  const filteredIntegrate: Record<string, FieldValue> = {};
  const filteredJob: Record<string, FieldValue> = {};
  const { language } = i18next;
  const translatedNameField = `name_${language}`;

  for (const key in data.integrate) {
    const fieldLabel = jobFields?.[key]?.[translatedNameField as any] ?? '';

    if (fieldLabel.toLowerCase().includes(searchTerm.toLowerCase())) {
      filteredIntegrate[key] = data.integrate[key];
      filteredJob[key] = data.job[key];
    }
  }

  return {
    integrate: filteredIntegrate,
    job: filteredJob,
  };
}
