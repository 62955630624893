import { TableCell, TableSortLabel } from '@mui/material';

import { useHeaderCell } from 'src/features/JobsTable/components/HeaderCell/useHeaderCell';
import { SUB_ROW_BORDER_STYLES } from 'src/features/JobsTable/utilities/styles';

export type HeaderCellProps = { fieldAlias: string; fieldName: string; isSubHeader?: boolean };

export function HeaderCell({ fieldAlias, fieldName, isSubHeader = false }: HeaderCellProps) {
  const { handleClickSort, isActive, orderDirection } = useHeaderCell({ fieldAlias });

  return (
    <TableCell
      sx={{
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        ...(isSubHeader && {
          bgcolor: 'primary.main',
          color: 'primary.contrastText',
          ...SUB_ROW_BORDER_STYLES,
        }),
      }}
      {...(isActive && { sortDirection: orderDirection })}
    >
      {!isSubHeader ? (
        <TableSortLabel active={isActive} direction={orderDirection} onClick={handleClickSort}>
          {fieldName}
        </TableSortLabel>
      ) : (
        fieldName
      )}
    </TableCell>
  );
}
