import axios, { InternalAxiosRequestConfig } from 'axios';

import { AsyncMutex } from './asyncMutex';

const mutex = new AsyncMutex();

export type TokensData = {
  expires_in: number;
  access_token: string;
  refresh_token: string;
};

export function deleteTokens() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('token_expiration');
  localStorage.removeItem('refresh_token');
}

function redirectToLogin() {
  window.location.href = '/login';
}

export function saveTokens(tokens: TokensData) {
  localStorage.setItem('access_token', tokens.access_token);
  localStorage.setItem(
    'token_expiration',
    (Math.floor(Date.now() / 1000) + tokens.expires_in).toString(),
  );
  localStorage.setItem('refresh_token', tokens.refresh_token);
}

export async function refreshToken() {
  const refreshToken = localStorage.getItem('refresh_token') || '';
  const response = await axios.post<{ data: TokensData }>(
    `${import.meta.env.VITE_API}tokens/refresh`,
    {
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
    },
  );

  saveTokens(response.data.data);
}

export async function requestInterceptor(config: InternalAxiosRequestConfig) {
  if (config.url?.endsWith('/tokens/refresh')) {
    return config;
  }

  const release = await mutex.acquire();

  const authTokenExpiration = localStorage.getItem('token_expiration');

  if (!authTokenExpiration) {
    logout();

    return config;
  }

  if (parseInt(authTokenExpiration) <= Math.floor(Date.now() / 1000)) {
    await refreshToken();
  }

  const token = localStorage.getItem('access_token') || '';

  config.headers['Authorization'] = `Bearer ${token}`;

  release();

  return config;
}

export function logout() {
  deleteTokens();

  redirectToLogin();
}
