import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';

import { AutoComplete } from 'src/features/Tasks/components/AutoComplete';
import {
  TeamMembers,
  useGetBackupUsersQuery,
  useUpdateUserBackupMutation,
} from 'src/pages/Dashboard/Dashboard.service';
import { useAppDispatch, useAppSelector } from 'src/utilities/hooks';
import { openWaveSnack } from '../../store/waveSnackSlice';

type Props = {
  myBackupUserActive: boolean | undefined;
  onSetMyBackupUserActive: Dispatch<SetStateAction<boolean>>;
  onSetUserIBackupActive: Dispatch<SetStateAction<boolean>>;
  userIBackupActive: boolean | undefined;
  users: TeamMembers;
};

export function Backup({
  myBackupUserActive,
  onSetMyBackupUserActive,
  onSetUserIBackupActive,
  userIBackupActive,
  users,
}: Props) {
  const dispatch = useAppDispatch();
  const userID = useAppSelector((state) => state.user.details.id);
  const { t } = useTranslation();

  const [myBackupUser, setMyBackupUser] = useState(0);
  const [userIBackup, setUserIBackup] = useState(0);

  const {
    data: myBackup,
    error: userIdError,
    isLoading: isLoadingMyBackupUser,
  } = useGetBackupUsersQuery({ userId: userID }, { skip: !Object.keys(users).length });

  const {
    data: userIBackupData,
    error: backupUserIdError,
    isLoading: isLoadingUserIBackup,
  } = useGetBackupUsersQuery({ backupUserId: userID }, { skip: !Object.keys(users).length });

  useEffect(() => {
    setUserIBackup(userIBackupData && userIBackupData?.active ? userIBackupData.user_id : 0);
    onSetUserIBackupActive(userIBackupData?.active || false);
    setMyBackupUser(myBackup && myBackup.active ? myBackup.user_id : 0);
    onSetMyBackupUserActive(myBackup?.active || false);
  }, [userIBackupData, myBackup]);

  if (userIdError || backupUserIdError) {
    const error = userIdError || backupUserIdError;

    dispatch(
      openWaveSnack({
        message: error?.message,
        type: 'error',
      }),
    );
  }

  const [updateUserBackup] = useUpdateUserBackupMutation();

  const [isLoadingMyBackupUserSwitch, setLoadingMyBackupUserSwitch] = useState(false);
  const [isLoadingUserIBackupSwitch, setLoadingUserIBackupSwitch] = useState(false);

  function handleSelectionChange({
    target: { id, value },
  }: {
    target: { id: string; value: string };
  }) {
    if (id === 'myBackupUser') setMyBackupUser(parseInt(value));
    else setUserIBackup(parseInt(value));
  }

  async function handleToggleMyBackupUser() {
    if (!userIBackupActive) setUserIBackup(0);

    setLoadingMyBackupUserSwitch(true);

    updateUserBackup({
      id: userID,
      status: myBackupUserActive ? 0 : 1,
      type: 'mybackup',
      url: `/users/${userID}/backup/${myBackupUser}`,
    })
      .unwrap()
      .then((response: any) => {
        if (myBackupUserActive) setMyBackupUser(0);

        onSetMyBackupUserActive(!myBackupUserActive);
        setLoadingMyBackupUserSwitch(false);
        dispatch(
          openWaveSnack({
            message: response,
            type: 'success',
          }),
        );
      })
      .catch((error: any) => {
        setLoadingMyBackupUserSwitch(false);
        dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      });
  }

  async function handleToggleUserIBackup() {
    setLoadingUserIBackupSwitch(true);
    setMyBackupUser(0);

    updateUserBackup({
      status: userIBackupActive ? 0 : 1,
      type: 'ibackup',
      url: `/users/${userIBackup}/backup/${userID}`,
    })
      .unwrap()
      .then((response: any) => {
        if (userIBackupActive) setUserIBackup(0);

        onSetUserIBackupActive(!userIBackupActive);
        setLoadingUserIBackupSwitch(false);

        dispatch(
          openWaveSnack({
            message: response,
            type: 'success',
          }),
        );
      })
      .catch((error: any) => {
        setLoadingUserIBackupSwitch(false);

        dispatch(
          openWaveSnack({
            message: error.message,
            type: 'error',
          }),
        );
      });
  }

  return (
    <Card className="d-flex flex-direction-column h-100p">
      <CardHeader
        classes={{ content: 'mx-10' }}
        className="p-5 primary-bg white-txt"
        title={<Typography component="p">{t('lib.backuprole', 'Backup')}</Typography>}
      />

      <CardContent className="d-flex flex-direction-column flex-grow-1 space-around">
        <Grid container>
          <Grid item marginRight="10px" position="relative" xs>
            <AutoComplete
              disabled={
                myBackupUserActive ||
                isLoadingMyBackupUser ||
                isLoadingMyBackupUserSwitch ||
                isLoadingUserIBackupSwitch
              }
              id="myBackupUser"
              label={t('usr-backup.mybackupuser', 'My Backup User')}
              onChange={handleSelectionChange}
              options={users}
              value={myBackupUser}
            />

            {isLoadingMyBackupUser && <CircularProgress className="buttonProgress" size={24} />}
          </Grid>

          <Grid item position="relative" xs={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={myBackupUserActive}
                  disabled={!myBackupUser || isLoadingMyBackupUserSwitch}
                  id="myBackupUserSwitch"
                  onChange={handleToggleMyBackupUser}
                />
              }
              label={myBackupUserActive ? t('toggle_on', 'ON') : t('toggle_off', 'OFF')}
            />

            {isLoadingMyBackupUserSwitch && (
              <CircularProgress className="buttonProgress" size={24} />
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid item marginRight="10px" position="relative" xs>
            <AutoComplete
              disabled={
                myBackupUserActive ||
                userIBackupActive ||
                isLoadingUserIBackup ||
                isLoadingMyBackupUserSwitch ||
                isLoadingUserIBackupSwitch
              }
              id="userIBackup"
              label={t('usr-backup.useribackup', 'User I Backup')}
              onChange={handleSelectionChange}
              options={users}
              value={userIBackup}
            />

            {isLoadingUserIBackup && <CircularProgress className="buttonProgress" size={24} />}
          </Grid>

          <Grid item position="relative" xs={4}>
            <FormControlLabel
              control={
                <Switch
                  checked={userIBackupActive}
                  disabled={!userIBackup || myBackupUserActive || isLoadingUserIBackupSwitch}
                  id="userIBackupSwitch"
                  onChange={handleToggleUserIBackup}
                />
              }
              label={userIBackupActive ? t('toggle_on', 'ON') : t('toggle_off', 'OFF')}
            />

            {isLoadingUserIBackupSwitch && (
              <CircularProgress className="buttonProgress" size={24} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
