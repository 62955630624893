import { RowTransformed } from 'src/features/JobsTable/JobsTable.service';
import { isArchivedStatus } from 'src/features/JobsTable/utilities/helperFunctions';

export function computeJobLink(
  jobId: number,
  jobType: RowTransformed['jobType'],
  status: RowTransformed['status'],
) {
  // Temp solution, until we have the following job types implemented in 3.0
  const hubxLegacyUrl = import.meta.env.VITE_HUBX_LEGACY_URL;

  if ((jobType === 'com' || jobType === 'sec') && hubxLegacyUrl) {
    return `${hubxLegacyUrl}index.php?act=${
      isArchivedStatus(status) ? 'arc' : 'job'
    }-${jobType}.edt&jobid=${jobId}`;
  }

  return `/jobs-${isArchivedStatus(status) ? 'arc' : 'job'}-${jobType}/${jobId}/job`;
}
