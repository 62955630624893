import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext, useFormState } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { recentJobsApi } from 'src/features/Frame/components/RecentJobs/RecentJobs.service';
import { useJobStatus } from 'src/features/JobForm/hooks';
import {
  AdditionActionTransformed,
  jobFormApi,
  StepTransformed,
  useChangeStatusMutation,
  useGetStepsQuery,
} from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { workflowApi } from 'src/features/Workflow/Workflow.service';
import { FieldsTransformed, FieldTransformed, jobApi } from 'src/pages/Job/Job.service';
import { useJobContext } from 'src/pages/Job/JobContext';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, usePreference, useRouteParams } from 'src/utilities/hooks';

type MoreAdditionalActionsButton = Omit<StepButton, 'onClick'> & {
  navigateIcon: JSX.Element;
  subItems: StepButton[];
};
type StepButton = {
  code: string;
  icon: JSX.Element;
  onClick: () => void;
  text: string;
};
type StepButtons = (StepButton | MoreAdditionalActionsButton)[];

export function useSteps() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { age, jobId } = useRouteParams();
  const { fields, isNewJob, jobType, setFields } = useJobContext();
  const { errors, isDirty } = useFormState();
  const { getValues, trigger } = useFormContext();
  const jobStatus = useJobStatus();
  const jobStatusValue = jobStatus?.value;

  const [isStatusChanging, setIsStatusChanging] = useState(false);
  const [isStepDialogOpen, setIsStepDialogOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [step, setStep] = useState<AdditionActionTransformed | StepTransformed>();
  const stepsButton = useRef<HTMLButtonElement | null>(null);
  const headerPreference = usePreference('job.btntips', 'Y');

  const { data: steps, isFetching } = useGetStepsQuery({ age, jobId, jobType }, { skip: isNewJob });
  const stepButtons = useMemo(() => {
    let stepButtons: StepButtons =
      steps?.steps.map((step) => {
        const { id, name, status, tooltip } = step;

        return {
          code: id.toString(),
          icon: <StatusIndicator jobType={jobType} label={status.toString()} size="extraSmall" />,
          onClick: () => {
            handleClickStep(step);
          },

          text: name,
          tooltipBody: tooltip,
          tooltipDisabled: headerPreference.value === 'N',
          tooltipHeader: name,
        };
      }) ?? [];

    const additionalActionButtons =
      steps?.additionalActions.map((additionalAction) => {
        const { id, name, status } = additionalAction;

        return {
          code: id.toString(),
          icon: <WaveIcon code={`job-form-action-bar-steps-additional-actions-${status}`} />,
          onClick: () => {
            handleClickStep(additionalAction);
          },
          text: name,
        };
      }) ?? [];

    if (steps?.additionalActions.length) {
      stepButtons = [
        ...stepButtons,
        {
          code: 'Additional Actions',
          icon: (
            <WaveIcon
              code="job-form-action-bar-steps-additional-actions-more"
              color="primary"
              fontSize="small"
            />
          ),
          navigateIcon: (
            <WaveIcon
              code="job-form-action-bar-steps-additional-actions-next"
              color="primary"
              fontSize="small"
            />
          ),
          subItems: additionalActionButtons,
          text: 'Additional Actions',
        },
      ];
    } else {
      stepButtons = [...stepButtons, ...additionalActionButtons];
    }

    return [...stepButtons];
  }, [steps]);

  const [changeStatus] = useChangeStatusMutation();

  function handleClickStep(step: AdditionActionTransformed | StepTransformed) {
    const { type } = step;

    if (type === 'link') {
      const { target, url } = step.link;

      window.open(url, target);
    } else if (setFields) {
      const { requiredFields } = step;

      setFields((previousFields) => {
        if (previousFields) {
          const mutatedFields = { ...previousFields };

          requiredFields.forEach((alias) => {
            if (mutatedFields[alias]) {
              mutatedFields[alias] = {
                ...(mutatedFields[alias] as FieldTransformed),
                isRequired: true,
              };
            }
          });

          return mutatedFields;
        } else {
          return previousFields;
        }
      });
      setStep(step);
    }

    handleCloseSubMenu();
  }

  function handleCloseStepDialog() {
    setIsStepDialogOpen(false);
    setStep(undefined);
  }

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  useEffect(() => {
    // After the required fields are updated and the step is set validate the form.
    if (jobStatusValue && step && !!fields) {
      const { id: stepId, stepFlags } = step;

      // trigger only validates fields that are rendered.
      // Only a single tab is rendered at a time,
      // even though the entire form is made up of multiple tabs.
      // Fields that are not rendered (i.e. they are on a tab that isn't currently displayed)
      // have to be validated with a loop and can't be focused.
      trigger(undefined, { shouldFocus: true }).then(async (isFormValid) => {
        validateNonRenderedFields(fields);

        function validateNonRenderedFields(fields: FieldsTransformed) {
          Object.values(fields).forEach(({ alias, isRequired }) => {
            if (isRequired) {
              const fieldValue = getValues(alias);

              if (!fieldValue) {
                errors[alias] = { type: 'required' };
                isFormValid = false;
              }
            }
          });
        }

        const shouldShowDialog = stepFlags.includes('show_dialog');

        if (!isFormValid) {
          const message = `In order to perform this step change, please fill out the following required fields: ${Object.keys(
            errors,
          )
            .map((jobFieldAlias) => fields[jobFieldAlias]?.name)
            .join(', ')}.`;

          dispatch(
            openWaveSnack({
              message,
              type: 'error',
            }),
          );
          setStep(undefined);
        } else if (shouldShowDialog) setIsStepDialogOpen(true);
        else {
          setIsStatusChanging(true);
          await changeStatus({ age, jobId, jobType, status: jobStatusValue, stepId })
            .unwrap()
            .then(({ message }) => {
              dispatch(
                openWaveSnack({
                  message,
                  type: 'success',
                }),
              );

              const shouldArchive = stepFlags.includes('archive');

              if (shouldArchive) {
                navigate(`/jobs-arc-${jobType}/${jobId}/job`);
                dispatch(recentJobsApi.util.invalidateTags(['RecentJobs']));
              } else {
                dispatch(jobApi.util.invalidateTags(['Job']));
                dispatch(jobFormApi.util.invalidateTags(['Steps', 'Tasks']));
                dispatch(workflowApi.util.invalidateTags(['Deadline', 'Workflow']));
              }
            })
            .catch(({ message }) =>
              dispatch(
                openWaveSnack({
                  message,
                  type: 'error',
                }),
              ),
            )
            .finally(() => {
              setIsStatusChanging(false);
              setStep(undefined);
            });
        }
      });
    }
  }, [jobStatusValue, step]);

  return {
    handleCloseStepDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isDirty,
    isFetching,
    isStatusChanging,
    isStepDialogOpen,
    isSubMenuOpen,
    step,
    stepButtons,
    stepsButton,
  };
}
