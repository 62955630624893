import { TableCell, Typography } from '@mui/material';

import { StyledLink } from 'src/features/JobsTable/components/StyledLink/StyledLink';
import { SUB_ROW_BORDER_STYLES } from 'src/features/JobsTable/utilities/styles';

import { CommonCellProps, JobLink } from 'src/features/JobsTable/types';

type TextCellProps = {
  value: string;
} & CommonCellProps &
  JobLink;
export function TextCell({ isSubRow, jobLink, paddingY, value }: TextCellProps) {
  const transformedValue = Array.isArray(value) && value.length > 1 ? value.join(' • ') : value;

  return (
    <TableCell sx={{ paddingY, whiteSpace: 'nowrap', ...(isSubRow && SUB_ROW_BORDER_STYLES) }}>
      <StyledLink href={jobLink}>
        <Typography variant="body2">{transformedValue}</Typography>
      </StyledLink>
    </TableCell>
  );
}
