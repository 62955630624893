import { TableCell } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { StyledLink } from 'src/features/JobsTable/components/StyledLink/StyledLink';
import { FlagTransformed } from 'src/features/JobsTable/JobsTable.service';
import { SUB_ROW_BORDER_STYLES } from 'src/features/JobsTable/utilities/styles';
import { WaveIcon } from 'src/features/WaveIcon';

import { CommonCellProps, JobLink } from 'src/features/JobsTable/types';

type FlagCellProps = { flags: FlagTransformed[] } & CommonCellProps & JobLink;

export function FlagCell({ flags, isSubRow, jobLink, paddingY }: FlagCellProps) {
  return (
    <TableCell
      sx={{
        paddingY,
        whiteSpace: 'nowrap',
        ...(isSubRow && SUB_ROW_BORDER_STYLES),
      }}
    >
      <StyledLink href={jobLink}>
        {flags.map(({ code, comment, name, reason }) => (
          <WaveTooltip
            body={comment}
            component={
              <WaveIcon
                className={code === 'onHold' ? 'warning-txt' : 'error-txt material-icons-outlined'}
                code={`job-flags-${code}`}
              />
            }
            header={reason}
            key={name}
            placement="top"
            type="simple"
          />
        ))}
      </StyledLink>
    </TableCell>
  );
}
