import { ReactNode, useEffect, useState } from 'react';

import { GlobalStyles } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import {
  extendAugmentColor,
  overrideStylesOfComponents,
} from 'src/components/WaveTheme/utilities/helperFunctions';
import { usePreference } from 'src/utilities/hooks';

import { InstallationColors } from 'src/components/WaveTheme/utilities/types/theme';
const installationModules = import.meta.glob([
  '/src/installations/**/*.js',
  '/src/installations/**/*.png',
  '/src/installations/**/*.jpg',
]);

const waveTheme = createTheme();
const extendedAugmentColor = extendAugmentColor(waveTheme.palette.augmentColor);

type WaveThemeProps = { children: ReactNode };

export function WaveTheme({ children }: WaveThemeProps) {
  const [clientColors, setClientColors] = useState<InstallationColors | undefined>();
  const clientIdPreference = usePreference('sys.mid', '');
  const [installationColors, setInstallationColors] = useState<InstallationColors | undefined>();
  const [installationLogo, setInstallationLogo] = useState<string | undefined>();
  const [theme, setTheme] = useState(waveTheme);

  theme.components = overrideStylesOfComponents(theme);

  function createWaveTheme(colors: InstallationColors) {
    return createTheme({
      clientColors,
      installationLogo,
      palette: {
        adm: extendedAugmentColor({ color: { main: '#ed7860' } }),
        all: extendedAugmentColor({ color: { main: '#c3d600' } }),
        art: extendedAugmentColor({ color: { main: '#459def' } }),
        com: extendedAugmentColor({ color: { main: '#d53a68' } }),
        fax: extendedAugmentColor({ color: { main: '#1351c9' } }),
        ffu: extendedAugmentColor({ color: { main: '#8b23a6' } }),
        filler: extendedAugmentColor({ color: { main: '#ECEEF1' } }),
        info: extendedAugmentColor({ color: { main: '#808080' } }),
        mas: extendedAugmentColor({ color: { main: '#00b3b3' } }),
        mis: extendedAugmentColor({ color: { main: '#008b74' } }),
        oddRow: extendedAugmentColor({ color: { main: '#f7f7f7' } }),
        primary: { main: colors.primary },
        pro: extendedAugmentColor({ color: { main: '#f5a507' } }),
        rep: extendedAugmentColor({ color: { main: '#006ca8' } }),
        sec: extendedAugmentColor({ color: { main: '#8b6892' } }),
        secondary: { main: colors.secondary },
        spc: extendedAugmentColor({ color: { main: '#0087a5' } }),
        text: { primary: colors.primary },
        tra: extendedAugmentColor({ color: { main: '#2fa148' } }),
      },
    });
  }

  function setCSSColorVariables(themeColors: InstallationColors) {
    Object.keys(themeColors).forEach((colorName) => {
      const colorValue = themeColors[colorName] as string;

      document.documentElement.style.setProperty(`--${colorName}`, colorValue);
    });
  }

  useEffect(() => {
    (async () => {
      const coloursPath = `/src/installations/${import.meta.env.VITE_INSTALLATION}/colors.js`;
      const { colors: installationColorsResponse } = (await installationModules[
        coloursPath
      ]()) as Record<'colors', InstallationColors>;

      installationColorsResponse.success = waveTheme.palette.success.main;
      installationColorsResponse.warning = waveTheme.palette.warning.main;
      installationColorsResponse.error = waveTheme.palette.error.main;
      installationColorsResponse.info = 'gray';
      setInstallationColors(installationColorsResponse);
      const logoPath = `/src/installations/${
        import.meta.env.VITE_INSTALLATION
      }/images/installationLogo.png`;
      const { default: installationLogoResponse } = (await installationModules[
        logoPath
      ]()) as Record<'default', string>;

      setInstallationLogo(installationLogoResponse);
      const logoBackground = `/src/installations/${
        import.meta.env.VITE_INSTALLATION
      }/images/installationPublicBackground.jpg`;
      const { default: installationPublicBackground } = (await installationModules[
        logoBackground
      ]()) as Record<'default', string>;

      document.documentElement.style.setProperty(
        '--installationPublicBackground',
        `url(${installationPublicBackground})`,
      );
    })();
  }, []);

  useEffect(() => {
    if (clientColors) {
      setCSSColorVariables(clientColors);
      setTheme(createWaveTheme(clientColors));
    }
  }, [clientColors]);

  useEffect(() => {
    if (clientIdPreference.value) {
      (async () => {
        try {
          const clientColours = `/src/installations/${import.meta.env.VITE_INSTALLATION}/client_${
            clientIdPreference.value
          }/colors.js`;
          const response = (await installationModules[clientColours]()) as Record<
            'colors',
            InstallationColors
          >;

          setClientColors({ ...response.colors });
        } catch (error) {
          if (clientColors !== installationColors) {
            setClientColors(installationColors);
          }
        }
      })();
    }
  }, [clientIdPreference.value]);

  useEffect(() => {
    if (installationColors && installationLogo) {
      setCSSColorVariables(installationColors);
      setTheme(createWaveTheme(installationColors));
    }
  }, [installationColors, installationLogo]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{
          '.pswp__top-bar': { backgroundColor: waveTheme.palette.primary.main },
          '.selection-area': {
            backgroundColor: '#4f90f22d',
            border: '1px solid #4f90f2',
            userSelect: 'none',
          },
        }}
      />

      {children}
    </ThemeProvider>
  );
}
