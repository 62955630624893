import { memo } from 'react';

import { Skeleton, Unstable_Grid2 as Grid } from '@mui/material';

import { FieldRenderer } from 'src/features/JobForm/components/FieldRenderer';
import { FieldTransformed } from 'src/pages/Job/Job.service';
import { Columns } from 'src/pages/Job/JobContext';
import { INPUT_HEIGHT } from 'src/utilities/constants';

type FieldsSectionProps = {
  alias: string;
  areFieldsLoaded?: boolean;
  isJobFetching: boolean;
  isReadOnly: boolean;
  jobField?: FieldTransformed;
  language: string;
  leftSpan: Columns;
  rightSpan: Columns;
  columns: Columns;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  onChangeSteeredField?: (fieldName: string) => void;
  fieldValueIndex?: number;
  isFirstBlockRow?: boolean;
};

function FieldsSection({
  alias,
  columns,
  fieldValueIndex,
  isFirstBlockRow,
  isJobFetching,
  isReadOnly,
  jobField,
  language,
  leftSpan,
  onChangeLogicBuilderField,
  onChangeSteeredField,
  rightSpan,
}: FieldsSectionProps) {
  return (
    <>
      {leftSpan ? <Grid xs={leftSpan} /> : null}

      <Grid sx={{ alignItems: 'center', display: 'flex' }} xs={columns}>
        {isJobFetching ? (
          <Skeleton height={INPUT_HEIGHT} variant="rounded" width="100%" />
        ) : (
          <FieldRenderer
            alias={alias}
            fieldValueIndex={fieldValueIndex}
            isFirstBlockRow={isFirstBlockRow}
            isReadOnly={isReadOnly}
            jobField={jobField}
            language={language}
            onChangeLogicBuilderField={onChangeLogicBuilderField}
            onChangeSteeredField={onChangeSteeredField}
          />
        )}
      </Grid>

      {rightSpan ? <Grid xs={rightSpan} /> : null}
    </>
  );
}

const MemoizedFieldSection = memo(FieldsSection);

export { MemoizedFieldSection as FieldsSection };
