import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Skeleton } from '@mui/material';

import { ExpandAllButton } from 'src/components/ExpandAllButton';
import { ActiveFilters } from 'src/features/ActiveFilters';
import { TranslationsButton } from 'src/features/Content/components/TranslationsButton';
import { useGetActionBarQuery, useGetContentListQuery } from 'src/features/Content/content.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useRouteParams } from 'src/utilities/hooks';

import { Action } from 'src/features/Content/Content.types';

type ActionBarProps = {
  onExpandAllClick: (expanded: boolean) => void;
  areAllCategoriesExpanded: boolean;
  onSelectAllClick: (selected: boolean) => void;
  onRemoveContent: () => void;
  areAllCategoriesSelected: boolean;
  isRemoveContentDisabled: boolean;
};

export function ActionBar({
  areAllCategoriesExpanded,
  areAllCategoriesSelected,
  isRemoveContentDisabled,
  onExpandAllClick,
  onRemoveContent,
  onSelectAllClick,
}: ActionBarProps) {
  const { age, jobId, jobType } = useRouteParams();
  const [selectedLanguages, setSelectedLanguages] = useState<Action[]>();
  const navigate = useNavigate();
  const { data: contentList, isFetching: isContentFetching } = useGetContentListQuery({
    age,
    jobId,
    src: jobType,
  });
  const { data: actionBar, isFetching: isActionBarFetching } = useGetActionBarQuery(
    {
      age,
      jobId,
      src: jobType,
    },
    { refetchOnMountOrArgChange: true },
  );

  const { t } = useTranslation();

  function handleDeleteFilter(filter: Record<string, string>) {
    setSelectedLanguages(selectedLanguages?.filter((language) => language.code !== filter.code));
  }

  if (isActionBarFetching || !actionBar || isContentFetching || !contentList) {
    return (
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Skeleton height={31} variant="rounded" width={300} />
      </Box>
    );
  }

  const contentItems = Object.keys(contentList);
  const translations = actionBar.find((item) => item.code === 'translations');

  return (
    <>
      <Box display="flex" gap={2} justifyContent="flex-end" mb={2}>
        {contentItems.length ? (
          <ExpandAllButton
            collapsedIcon="job-phrase-action-bar-collapse-all"
            expandedIcon="job-phrase-action-bar-expand-all"
            isExpanded={areAllCategoriesExpanded}
            onToggleAreAllSubJobsExpanded={() => onExpandAllClick(!areAllCategoriesExpanded)}
            size="small"
          />
        ) : null}

        {contentItems.length ? (
          <Button
            onClick={() => onSelectAllClick(!areAllCategoriesSelected)}
            size="small"
            startIcon={
              <WaveIcon
                code={`job-phrase-action-bar-${
                  !areAllCategoriesSelected ? 'select' : 'deselect'
                }-all`}
              />
            }
            variant="outlined"
          >
            {areAllCategoriesSelected
              ? t('lib.desel.all', 'Deselect all')
              : t('lib.sel.all', 'Select all')}
          </Button>
        ) : null}

        {translations && contentItems.length ? (
          <TranslationsButton
            languages={translations.subItems || []}
            onSelectLanguage={setSelectedLanguages}
            selectedLanguages={selectedLanguages}
          />
        ) : null}

        {contentItems.length ? (
          <Button
            onClick={() => navigate('edit')}
            startIcon={<WaveIcon code="job-phrase-action-bar-edit-content" />}
          >
            {t('job-cms.edit', 'Edit Content')}
          </Button>
        ) : null}

        {!contentItems.length ? (
          <Button
            onClick={() => navigate('new')}
            startIcon={<WaveIcon code="job-phrase-action-bar-add-content" />}
          >
            {t('job-cms.add', 'Add Content')}
          </Button>
        ) : (
          <LoadingButton
            color="error"
            disabled={isRemoveContentDisabled}
            onClick={onRemoveContent}
            startIcon={<WaveIcon code="delete" />}
          >
            Remove Content
          </LoadingButton>
        )}

        {/* Not implemented yet */}
        {/* <ActionsButton /> */}
      </Box>

      {selectedLanguages && selectedLanguages.length ? (
        <ActiveFilters
          activeFilters={selectedLanguages.map(({ code, text }) => ({ code, text }))}
          filterLabel={t('lib.lang', 'Language') as string}
          onDeleteActiveFilter={handleDeleteFilter}
          onDeleteAllActiveFilters={() => setSelectedLanguages([])}
        />
      ) : null}
    </>
  );
}
