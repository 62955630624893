import { Container, Stack } from '@mui/material';

import { ActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs';
import { FilterDrawer } from 'src/components/FilterDrawer';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { CONTAINER_STYLES, STACK_STYLES } from 'src/utilities/stylesOfJobs';

export function SubJobs() {
  const {
    areActiveFiltersVisible,
    areJobsFetching,
    columns,
    deadlines,
    handleChangePageSearch,
    handleToggleActiveFiltersVisibility,
    rows,
    searchTerm,
    setAreActiveFiltersVisible,
    setPageHistory,
    totalRowCount,
  } = useJobs();

  return (
    <Stack flexGrow={1} overflow="hidden" role="tabpanel">
      <Container disableGutters maxWidth={false} sx={CONTAINER_STYLES}>
        <ActionBar
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          searchTerm={searchTerm}
        />

        <ActiveFiltersOfJobs
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <Stack {...STACK_STYLES}>
        <FilterDrawer
          areActiveFiltersVisible={areActiveFiltersVisible}
          handleToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          parentLocation="sub-jobs"
        />

        <JobsTable
          areActiveFiltersVisible={areActiveFiltersVisible}
          areJobsFetching={areJobsFetching}
          columns={columns}
          deadlines={deadlines}
          rows={rows}
          setPageHistory={setPageHistory}
          totalRowCount={totalRowCount}
        />
      </Stack>
    </Stack>
  );
}
