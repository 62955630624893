import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Paper, Skeleton, Stack, TablePagination } from '@mui/material';
import { styled } from '@mui/material/styles';

import { WaveIcon } from 'src/features/WaveIcon';
import { useDebounce, useRouteParams } from '../../../../utilities/hooks';
import { SubMenu } from '../../../SubMenu';
import {
  useGetNoContentPlaceholderQuery,
  useGetSearchOptionsQuery,
  useLazyGetSearchResultsQuery,
} from '../../content.service';
import { StatusIcon } from '../StatusIcon';
import { WidgetBase } from '../WidgetBase';

import { Filter } from '../../../History/History.types';
import type {
  ContentCategoriesList,
  SearchResult,
  TemplateSelectionParams,
} from '../../Content.types';

type SearchResultsProps = {
  selectedCategoryIndex: string;
  selectedCategory?: ContentCategoriesList;
};

const ItemContent = styled(Box)`
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  font-size: ${({ theme }) => theme.typography.body2};
`;

export function SearchResults({ selectedCategory, selectedCategoryIndex }: SearchResultsProps) {
  const { t } = useTranslation();
  const [categoryIndex, itemIndex] = selectedCategoryIndex.split('_');
  const { age, jobId, jobType } = useRouteParams();
  const { clearErrors, setValue, watch } = useFormContext<TemplateSelectionParams>();
  const content = watch(`categories.${+categoryIndex}.items.${+itemIndex}.result`);
  const debouncedValue = useDebounce(content, 500);
  const [activeFilters, setActiveFilters] = useState<{ code: string; text: string }[]>([]);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchFields, setSearchFields] = useState({});
  const [shouldTriggerRequest, setShouldTriggerRequest] = useState(true);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const [searchResults, { data: searchResultsData = [], isFetching: areSearchResultsFetching }] =
    useLazyGetSearchResultsQuery();

  const { data: searchOptions = [], isFetching: areSearchOptionsFetching } =
    useGetSearchOptionsQuery(
      {
        age,
        jobId,
        src: jobType,
      },
      { refetchOnMountOrArgChange: true },
    );
  const { data: noContentData } = useGetNoContentPlaceholderQuery(
    {},
    { refetchOnMountOrArgChange: true },
  );

  const [filteredSearchResults, setFilteredSearchResults] =
    useState<SearchResult[]>(searchResultsData);

  function triggerSearch() {
    searchResults({
      category_id: selectedCategory?.id,
      content: selectedCategory?.readonly ? undefined : debouncedValue,
      fields: searchFields,
      status: statusFilter,
    });
  }

  useEffect(() => {
    if (shouldTriggerRequest) {
      if (debouncedValue && debouncedValue !== noContentData?.text && !selectedCategory?.readonly) {
        triggerSearch();
      }
    } else {
      setShouldTriggerRequest(true);
    }
  }, [
    debouncedValue,
    Object.keys(searchFields).length,
    selectedCategory?.readonly,
    statusFilter.length,
  ]);

  useEffect(() => {
    if (!areSearchResultsFetching) {
      setFilteredSearchResults(
        searchResultsData.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage),
      );
    }
  }, [areSearchResultsFetching]);

  useEffect(() => {
    setSearchFields({});
    setStatusFilter([]);
    setActiveFilters([]);
    setFilteredSearchResults([]);
    setCurrentPage(0);

    if (selectedCategory?.readonly) {
      triggerSearch();
    }
  }, [selectedCategoryIndex]);

  const items = useMemo(() => {
    if (areSearchOptionsFetching) {
      return null;
    }

    const metadata = searchOptions.find((option) => option.code === 'metadata');

    const statusFilter = {
      checkbox: true,
      code: 'status',
      onClick: (checkedItems: Filter[]) => {
        handleOptionClick(checkedItems);
      },
      text: 'Approved content',
    };

    const metadataParams = Object.keys(metadata?.subItems || {}).map((subItemKey) => ({
      checkbox: true,
      code: subItemKey,
      onClick: (checkedItems: Filter[]) => {
        handleOptionClick(checkedItems);
      },
      text: metadata?.subItems[subItemKey] || '',
    }));

    return [statusFilter, ...metadataParams];
  }, [areSearchOptionsFetching]);

  function handleOptionClick(checkedItems: Filter[]) {
    const filters: Record<string, string> = {};

    checkedItems
      .filter((item) => item.code !== 'status')
      .forEach((item) => {
        filters[item.code] = item.text;
      });

    const status = checkedItems.find((item) => item.code === 'status');

    if (status) {
      setStatusFilter(['APPROVED']);
    } else {
      setStatusFilter([]);
    }

    setActiveFilters([...activeFilters, ...checkedItems]);
    setSearchFields(filters);
  }

  function handleOpenSubMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setAnchorElWidth(e.currentTarget.offsetWidth);
    setIsSubMenuOpen(true);
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  function handleSelectResult(result: SearchResult) {
    setShouldTriggerRequest(false);
    setValue(`categories.${+categoryIndex}.items.${+itemIndex}.status`, result.status);
    setValue(`categories.${+categoryIndex}.items.${+itemIndex}.result`, result.content);
    clearErrors(`categories.${+categoryIndex}.items.${+itemIndex}`);
  }

  return (
    <WidgetBase
      action={
        <Box p={1.5}>
          <Button
            onClick={handleOpenSubMenu}
            startIcon={<WaveIcon code="job-phrase-search-results-options" fontSize="small" />}
          >
            <Trans i18nKey="lib.opt">Options</Trans>
          </Button>

          {isSubMenuOpen ? (
            <SubMenu
              anchorEl={anchorEl}
              anchorElMinWidth={anchorElWidth}
              anchorOriginHorizontalSubitem="right"
              anchorOriginVerticalSubitem="top"
              checkedItems={activeFilters}
              fontSize="caption"
              items={items || []}
              onClose={handleCloseSubMenu}
              open={isSubMenuOpen}
              transformOriginHorizontalSubitem="left"
              transformOriginVerticalSubitem="top"
            />
          ) : null}
        </Box>
      }
      title={t('search_results_title', 'Search Results')}
    >
      <Box display="flex" flexDirection="column" flexGrow="1" justifyContent="space-between">
        <Stack gap={0.5} id="search-results-container" overflow="auto" p={0.5}>
          {areSearchResultsFetching
            ? [...Array(10)].map((_, index) => (
                <Skeleton height={42} key={index} variant="rounded" />
              ))
            : filteredSearchResults.map((item, index) => (
                <Box
                  alignItems="center"
                  border="1px solid #DEE1E3"
                  boxShadow="0 0 3px #00000033"
                  component={Paper}
                  display="flex"
                  gap={0.5}
                  key={index}
                  onClick={() => handleSelectResult(item)}
                  p={1}
                  sx={{ cursor: 'pointer' }}
                >
                  <StatusIcon size="small" status={item.status} />

                  <ItemContent dangerouslySetInnerHTML={{ __html: item.content }} />
                </Box>
              ))}
        </Stack>

        <Box borderTop={1} sx={{ borderColor: 'grey.500' }} width="100%">
          <TablePagination
            component="div"
            count={searchResultsData.length}
            labelRowsPerPage={t('file-ser.rows_per_page', 'Rows per page')}
            onPageChange={(e, page: number) => setCurrentPage(page)}
            onRowsPerPageChange={(e) => setRowsPerPage(+e.target.value)}
            page={currentPage}
            rowsPerPage={rowsPerPage}
          />
        </Box>
      </Box>
    </WidgetBase>
  );
}
