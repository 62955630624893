import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Skeleton,
  TextField,
} from '@mui/material';

import { WaveIcon } from 'src/features/WaveIcon';

type SearchBarProps = {
  isFetching: boolean;
  searchTerm: string;
  shouldHideSameValues: boolean;
  onSearchTermChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onToggleHideSameValues: () => void;
};

export function SearchBar({
  isFetching,
  onSearchTermChange,
  onToggleHideSameValues,
  searchTerm,
  shouldHideSameValues,
}: SearchBarProps) {
  const { t } = useTranslation();

  return isFetching ? (
    <Box marginBottom={1}>
      <Skeleton height={40} variant="rectangular" width={500} />
    </Box>
  ) : (
    <Box display="flex">
      <Box marginRight={1} my={1} width={300}>
        <TextField
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WaveIcon code="search" />
              </InputAdornment>
            ),
          }}
          label={t('search_fields_field', 'Search Fields')}
          onChange={onSearchTermChange}
          size="small"
          value={searchTerm}
        />
      </Box>

      <FormControlLabel
        control={
          <Checkbox
            checked={shouldHideSameValues}
            onClick={onToggleHideSameValues}
            value={shouldHideSameValues}
          />
        }
        label={
          shouldHideSameValues
            ? t('show_same_values', 'Show Same Values')
            : t('hide_same_values', 'Hide Same Values')
        }
      />
    </Box>
  );
}
