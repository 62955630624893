import { MouseEvent, useEffect, useState } from 'react';

import { Box } from '@mui/material';

import { Button } from 'src/components/Button';
import { ActiveFilters } from 'src/features/ActiveFilters';
import { NewCommentDialog } from 'src/features/History/components/NewCommentDialog';
import { useGetHistoryActionBarQuery } from 'src/features/History/History.service';
import { SendEmailDialog } from 'src/features/SendEmailDialog';
import { SubMenu, SubMenuItemType } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';
import { useRouteParams } from 'src/utilities/hooks';

import { Filter, HistoryItemType } from 'src/features/History/History.types';

type ActionBarProps = {
  activeHistoryFilters: Filter[];
  expandedHistoryItemIds: string[];
  history: HistoryItemType[];
  historyFilters: Filter[];
  onClickFilter: (activeFilters: Filter[]) => void;
  onToggleExpandAll: (state: boolean) => void;
};

export function ActionBar({
  activeHistoryFilters,
  expandedHistoryItemIds,
  history,
  historyFilters,
  onClickFilter,
  onToggleExpandAll,
}: ActionBarProps) {
  const { age, jobId, jobType } = useRouteParams();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [anchorElWidth, setAnchorElWidth] = useState(0);
  const [areAllHistoryItemsExpanded, setAreAllHistoryItemsExpanded] = useState(true);
  const [isSubMenuOpen, setISubMenuOpen] = useState(false);
  const [items, setItems] = useState<SubMenuItemType[]>([]);
  const [isNewCommentDialogOpen, setNewCommentDialogOpen] = useState(false);
  const { data: historyActionBar } = useGetHistoryActionBarQuery({
    age,
    jobid: jobId,
    src: jobType,
  });
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false);

  function handleClickExpandCollapseAll() {
    onToggleExpandAll(areAllHistoryItemsExpanded);
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setAnchorElWidth(0);
    setISubMenuOpen(false);
  }

  function handleDeleteAllHistoryFilters() {
    onClickFilter([]);
  }

  function handleDeleteHistoryFilter(deleteFilter: Record<string, string>) {
    onClickFilter(activeHistoryFilters.filter((filter) => filter !== deleteFilter));
  }

  function handleOpenNewCommentDialog() {
    setNewCommentDialogOpen(true);
  }

  function handleOpenSubMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setAnchorElWidth(e.currentTarget.offsetWidth);
    setItems(
      historyFilters.map(({ code, text }) => ({
        checkbox: true,
        code,
        onClick: (checkedItems: Filter[]) => {
          onClickFilter(checkedItems);
        },
        text,
      })),
    );
    setISubMenuOpen(true);
  }

  useEffect(() => {
    if (!history) return;

    if (
      areAllHistoryItemsExpanded &&
      expandedHistoryItemIds.length ===
        history.filter((item) => item.email_list || item.message).length
    ) {
      setAreAllHistoryItemsExpanded(false);
    } else if (!areAllHistoryItemsExpanded && expandedHistoryItemIds.length === 0) {
      setAreAllHistoryItemsExpanded(true);
    }
  }, [expandedHistoryItemIds, history]);

  function handleOpenEmailDialog() {
    setIsEmailDialogOpen(true);
  }

  function handleClickActionBar(e: MouseEvent<HTMLButtonElement>, code: string) {
    if (code === 'filters') handleOpenSubMenu(e);
    else if (code === 'expand-all') handleClickExpandCollapseAll();
    else if (code === 'comment') handleOpenNewCommentDialog();
    else if (code === 'email') handleOpenEmailDialog();
  }

  return (
    <>
      <Box display="flex" gap={1} justifyContent="end" marginY={2}>
        {historyActionBar?.map(({ code, text }) => (
          <Button
            key={code}
            onClick={(e) => handleClickActionBar(e, code)}
            startIcon={
              <WaveIcon
                code={
                  code === 'expand-all'
                    ? `job-history-action-bar-${
                        areAllHistoryItemsExpanded ? 'expand-more' : 'expand-less'
                      }`
                    : `job-history-action-bar-${code}`
                }
              />
            }
          >
            {code === 'expand-all'
              ? areAllHistoryItemsExpanded
                ? 'Expand All'
                : 'Collapse All'
              : text}
          </Button>
        ))}
      </Box>

      {isSubMenuOpen ? (
        <SubMenu
          anchorEl={anchorEl}
          anchorElMinWidth={anchorElWidth}
          anchorOriginHorizontalSubitem="right"
          anchorOriginVerticalSubitem="top"
          checkedItems={activeHistoryFilters}
          fontSize="caption"
          items={items}
          onClose={handleCloseSubMenu}
          open={isSubMenuOpen}
          transformOriginHorizontalSubitem="left"
          transformOriginVerticalSubitem="top"
        />
      ) : null}

      {activeHistoryFilters.length ? (
        <ActiveFilters
          activeFilters={activeHistoryFilters}
          onDeleteActiveFilter={handleDeleteHistoryFilter}
          onDeleteAllActiveFilters={handleDeleteAllHistoryFilters}
        />
      ) : null}

      <NewCommentDialog
        isOpen={isNewCommentDialogOpen}
        onClose={() => setNewCommentDialogOpen(false)}
      />

      <SendEmailDialog
        isOpen={isEmailDialogOpen}
        onClose={() => setIsEmailDialogOpen(false)}
        title="lib.email.new"
      />
    </>
  );
}
