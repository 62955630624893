import { Checkbox, FormControlLabel } from '@mui/material';

import { Recipient as RecipientType } from 'src/features/SendEmailDialog/Email.service';

type RecipientProps = {
  onClickRecipientCheckbox: (selectedRole: string, selectedId: number) => void;
  recipient: RecipientType;
};

export function Recipient({
  onClickRecipientCheckbox,
  recipient: { checked, id, name, role },
}: RecipientProps) {
  function handleClickRecipientCheckbox() {
    onClickRecipientCheckbox(role, id);
  }

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleClickRecipientCheckbox} />}
      label={`${role} - ${name}`}
    />
  );
}
