import { MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLocation } from 'react-router-dom';

import { Box, Button, Dialog, Skeleton } from '@mui/material';

import { AddRevisorForm } from 'src/components/AddRevisorForm';
import {
  AddRevisorPayload,
  AddRevisorToExistingApprovalPayload,
} from 'src/components/AddRevisorForm/AddRevisorForm';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import {
  useAddRevisorMutation,
  useGetActionsQuery,
  useGetApprovalsQuery,
} from 'src/features/Approvals/Approvals.service';
import { ActionDialog } from 'src/features/Approvals/components/ActionDialog';
import { SendEmailDialog } from 'src/features/SendEmailDialog';
import { SubMenu, SubMenuItemType } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useRouteParams, useViewer } from 'src/utilities/hooks';

type ActionBarProps = {
  selectedFileIds: number[];
};

type ActiveUserAction = { code: string; flag: string; text: string };
export type AplAction = ActiveUserAction | FileAction | MainAction;
type FileAction = { code: string; text: string };
type MainAction = { code: string; prefix: string; text: string };

export function ActionBar({ selectedFileIds }: ActionBarProps) {
  const { age, jobId, jobType } = useRouteParams();

  const [addRevisor] = useAddRevisorMutation();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [aplAction, setAplAction] = useState<AplAction>();
  const dispatch = useAppDispatch();
  const [isAddRevisorDialogOpen, setIsAddRevisorDialogOpen] = useState(false);
  const [isAplActionDialogOpen, setIsAplActionDialogOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [revisorAction, setRevisorAction] = useState<MainAction>();
  const { t } = useTranslation();
  const location = useLocation();
  const isViewer = location.pathname.includes('viewer');

  const { openViewerPage } = useViewer();

  const {
    data: actions,
    isLoading: areActionsLoading,
    isSuccess: areActionsLoadedSuccessfully,
  } = useGetActionsQuery({ age, jobid: jobId, src: jobType }, { refetchOnMountOrArgChange: true });

  const { data: approvals } = useGetApprovalsQuery({
    age,
    jobid: jobId,
    src: jobType,
  });

  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState<boolean>(false);

  const items = useMemo<SubMenuItemType[]>(() => {
    if (areActionsLoadedSuccessfully) {
      return actions?.active_user_actions.map((activeUserAction) => {
        const actionCode = activeUserAction.code;

        return {
          code: actionCode,
          flag: activeUserAction.flag,
          icon: (
            <WaveIcon
              code={`job-approvals-actions-${actionCode}`}
              color={
                actionCode === 'amendment'
                  ? 'error'
                  : actionCode === 'conditional'
                  ? 'warning'
                  : actionCode === 'approval'
                  ? 'success'
                  : 'primary'
              }
              fontSize="small"
            />
          ),
          onClick: () => {
            handleAplActions(activeUserAction);
          },
          text: activeUserAction.text,
        };
      });
    }

    return [];
  }, [areActionsLoadedSuccessfully, actions?.active_user_actions.length]);

  async function handleAddRevisor(parameters: AddRevisorPayload) {
    const { duration, externalUsers, groups, position, prefixes, users } =
      parameters as AddRevisorToExistingApprovalPayload;

    return await addRevisor({
      age,
      duration,
      jobId,
      jobType,
      method: (revisorAction as MainAction).code,
      position,
      prefixes,
      ...(externalUsers && { externalUsers }),
      ...(groups && { groups }),
      ...(users && { users }),
    })
      .unwrap()
      .then(() => {
        function buildSuccessMessage(
          externalUsers?: string[],
          groups?: string[],
          users?: string[],
        ) {
          const endOfMessage = 'successfully added to the approval loop.';

          if (
            (users?.length === 1 && !externalUsers?.length && !groups?.length) ||
            (externalUsers?.length === 1 && !users?.length && !groups?.length)
          ) {
            return `The revisor was ${endOfMessage}`;
          }

          if (groups?.length === 1 && !users?.length && !externalUsers?.length) {
            return `The group was ${endOfMessage}`;
          }

          if (groups?.length && groups?.length > 1 && !users?.length && !externalUsers?.length) {
            return `The groups were ${endOfMessage}`;
          }

          return `The revisors were ${endOfMessage}`;
        }

        dispatch(
          openWaveSnack({
            message: buildSuccessMessage(externalUsers, groups, users),
            type: 'success',
          }),
        );
        handleCloseAddRevisorDialog();
      })
      .catch(({ message }) => {
        dispatch(
          openWaveSnack({
            message: message,
            type: 'error',
          }),
        );
      });
  }

  function handleAplActions(item: AplAction) {
    const itemCode = item.code;

    if (itemCode === 'add' || itemCode === 'expand' || itemCode === 'forward') {
      handleOpenAddRevisorDialog();
      setRevisorAction(item as MainAction);
    } else if (itemCode === 'amendment' || itemCode === 'conditional' || itemCode === 'approval') {
      handleOpenAplActionDialog();
      setAplAction(item);
    } else if (itemCode === 'compare') {
      openViewerPage({ compareFile: true, fileId: selectedFileIds, fileJobID: jobId });
    }

    handleCloseSubMenu();
  }

  function handleCloseAddRevisorDialog() {
    setIsAddRevisorDialogOpen(false);
  }

  function handleCloseAplActionDialog() {
    setIsAplActionDialogOpen(false);
  }

  function handleCloseSubMenu() {
    setAnchorEl(null);
    setIsSubMenuOpen(false);
  }

  function handleOpenAddRevisorDialog() {
    setIsAddRevisorDialogOpen(true);
  }

  function handleOpenAplActionDialog() {
    setIsAplActionDialogOpen(true);
  }

  function handleOpenSubMenu(e: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(e.currentTarget);
    setIsSubMenuOpen(true);
  }

  function handleOpenEmailDialog() {
    setIsEmailDialogOpen(true);
  }

  if (areActionsLoading) {
    return (
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Skeleton height={31} variant="rounded" width="100%" />
      </Box>
    );
  }

  return (
    <>
      <Box display="flex" justifyContent="space-between" pb={2}>
        <Box visibility={isViewer ? 'hidden' : 'visible'}>
          {actions?.file_actions.map((fileAction) => {
            return (
              <WaveTooltip
                body={
                  selectedFileIds.length < 2 && fileAction.code === 'compare'
                    ? (t('two_files_selected', 'At least two files need to be selected.') as string)
                    : undefined
                }
                component={
                  <Button
                    disabled={selectedFileIds.length < 2 && fileAction.code === 'compare'}
                    onClick={() => {
                      handleAplActions(fileAction);
                    }}
                    startIcon={<WaveIcon code={`job-approvals-file-actions-${fileAction.code}`} />}
                  >
                    {fileAction.text}
                  </Button>
                }
                key={fileAction.text}
                placement="top"
                type="simple"
              />
            );
          })}
        </Box>

        <Box display="flex" gap={1}>
          <Box>
            {actions?.main_actions.map((mainAction) => {
              return (
                <Button
                  key={mainAction.text}
                  onClick={() => {
                    handleAplActions(mainAction);
                  }}
                  startIcon={<WaveIcon code={`job-approvals-action-bar-${mainAction.code}`} />}
                >
                  {mainAction.text}
                </Button>
              );
            })}
          </Box>

          <Box>
            {actions && actions.active_user_actions.length > 0 && (
              <Button
                color="secondary"
                id="APL"
                onClick={handleOpenSubMenu}
                startIcon={<WaveIcon code="job-approvals-action-bar-more-vert" />}
                sx={{ bgcolor: `${isSubMenuOpen ? 'secondary.dark' : ''}` }}
              >
                {t('lib.actions', 'Actions')}
              </Button>
            )}
          </Box>

          <Box sx={{ display: isViewer ? 'none' : 'block' }}>
            {actions?.email_actions?.map((emailAction) => {
              return (
                <Button
                  key={emailAction.text}
                  onClick={handleOpenEmailDialog}
                  startIcon={<WaveIcon code={`job-approvals-action-bar-${emailAction.code}`} />}
                >
                  {emailAction.text}
                </Button>
              );
            })}
          </Box>
        </Box>
      </Box>

      {isSubMenuOpen && (
        <SubMenu
          anchorEl={anchorEl}
          anchorOriginHorizontalSubitem="left"
          anchorOriginVerticalSubitem="top"
          fontSize="caption"
          items={items}
          onClose={handleCloseSubMenu}
          open={isSubMenuOpen}
          transformOriginHorizontalSubitem="right"
          transformOriginVerticalSubitem="top"
        />
      )}

      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseAddRevisorDialog}
        open={isAddRevisorDialogOpen}
      >
        {isAddRevisorDialogOpen ? (
          <>
            <WaveDialogTitle
              onClose={handleCloseAddRevisorDialog}
              title={(revisorAction as MainAction).text}
            />

            <AddRevisorForm
              activeApproval={approvals?.[0].approval}
              loopId={approvals?.find(({ status }) => status === 'open')?.id}
              onSubmit={handleAddRevisor}
              revisorAction={(revisorAction as MainAction).code}
            />
          </>
        ) : null}
      </Dialog>

      <ActionDialog
        aplAction={aplAction}
        isOpen={isAplActionDialogOpen}
        onClose={handleCloseAplActionDialog}
      />

      <SendEmailDialog
        isOpen={isEmailDialogOpen}
        onClose={() => setIsEmailDialogOpen(false)}
        title="lib.email.new"
      />
    </>
  );
}
