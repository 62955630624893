import { memo, SyntheticEvent } from 'react';
import ReactDOM from 'react-dom';

import { Autocomplete, Popper, TextField } from '@mui/material';

import { objectToOrderedArray } from 'src/utilities/helperFunctions';

type TaskAutoCompleteProps = {
  disabled: boolean | undefined;
  id?: string;
  label: string;
  onChange: any;
  options: any;
  value: any;
};

type TaskAutoCompleteValue = { label: any; value: string } | null;

function AutoCompleteComponent({
  disabled,
  id,
  label,
  onChange,
  options: propOptions,
  value,
}: TaskAutoCompleteProps) {
  const options = objectToOrderedArray(propOptions).map((option) => ({
    label: option[1],
    value: option[0],
  }));

  const hasPropOptionValue = propOptions && Object.keys(propOptions).length;

  const optionValue = {
    label: (hasPropOptionValue ? propOptions[value || '0'] : '') as string,
    value: (value || '') as string,
  } as { label: string; value: string };

  function handleOnChange(e: SyntheticEvent<Element, Event>, newValue: TaskAutoCompleteValue) {
    e?.preventDefault();
    onChange({
      target: {
        id,
        value: newValue?.value || '0',
      },
    });
  }

  return (
    <Autocomplete
      blurOnSelect
      disabled={disabled}
      disablePortal
      fullWidth
      getOptionKey={(option: any) => option.value}
      getOptionLabel={(option: any) => option.label as string}
      id="combo-box-demo"
      onChange={handleOnChange}
      options={options}
      placeholder={label || ''}
      PopperComponent={(props) => ReactDOM.createPortal(<Popper {...props} />, document.body)}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
          label={label || ''}
          size="small"
        />
      )}
      size="small"
      value={optionValue}
    />
  );
}

export const AutoComplete = memo(AutoCompleteComponent);
