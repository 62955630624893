import {
  BaseSyntheticEvent,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import { isSelectField } from 'src/features/JobForm/components/FieldMapper2/utilities/helperFunctions';
import {
  jobFormApi,
  useChangeLogicBuilderFieldMutation,
  useCreateJobMutation,
  useSteerCascadingSelectionMutation,
  useUpdateJobMutation,
  VisibilityOfSections,
} from 'src/features/JobForm/JobForm.service';
import { workflowApi } from 'src/features/Workflow/Workflow.service';
import {
  FieldsTransformed,
  FieldValues,
  jobApi,
  JobValues,
  OtherJobValues,
  Preview,
  useGetJobQuery,
  useGetTabsQuery,
} from 'src/pages/Job/Job.service';
import { getDefaultValues } from 'src/pages/Job/utilities/helperFunctions';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { extractFieldValue, filterFormData } from 'src/utilities/helperFunctions';
import {
  useAppDispatch,
  useAppSelector,
  usePreference,
  useQueryParams,
  useRouteParams,
} from 'src/utilities/hooks';

const installationModules = import.meta.glob('/src/installations/*/client_*/form/*/*Layout.js');

export type Columns = NumberRange<2, 13>;
type Enumerate<
  N extends number,
  Accumulator extends number[] = [],
> = Accumulator['length'] extends N
  ? Accumulator[number]
  : Enumerate<N, [...Accumulator, Accumulator['length']]>;
type JobContextProps = {
  age?: string;
  areAllFieldsDisabled: boolean;
  fieldLayout?: FieldLayout;
  fields?: FieldsTransformed;
  handleChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  handleChangeSteeredField?: (fieldName: string) => void;
  handleSubmit?: (e?: BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  hasEditRights: boolean;
  isJobFetching: boolean;
  isNewJob: boolean;
  jobType?: string;
  preview: Preview;
  setFields?: Dispatch<SetStateAction<FieldsTransformed>>;
  values?: JobValues;
  visibilityOfSections: VisibilityOfSections;
  areDefaultValuesSet: boolean;
};
type JobDataProviderProps = {
  children: ReactNode;
};
export type FieldLayouts = Record<string, FieldLayout>;
export type FieldLayout = {
  isTwoColumns: boolean;
  sections: Sections;
};
export type Sections = Section[];
type NumberRange<Minimum extends number, MaximumNotIncluded extends number> = Exclude<
  Enumerate<MaximumNotIncluded>,
  Enumerate<Minimum>
>;

export type Aliases = string[];

type Row = {
  fixedFields: Aliases;
  scrollableFields: Aliases;
  title: string;
};

export type Table = {
  rows: Array<Row>;
};

export type FieldLayoutField = {
  alias: string;
  columns: Columns;
  leftSpan: Columns;
  rightSpan: Columns;
  readonly: boolean;
};

export type Block = {
  code: string;
  fields: FieldLayoutFields;
  table?: Table;
};

export type Section = {
  blocks?: Array<Block>;
  code: string;
  fields?: FieldLayoutFields;
  table?: Table;
  title: string;
  sections?: Sections;
};
export type FieldLayoutFields = FieldLayoutField[];

const initialState: JobContextProps = {
  areAllFieldsDisabled: false,
  areDefaultValuesSet: false,
  hasEditRights: false,
  isJobFetching: true,
  isNewJob: false,
  preview: {},
  visibilityOfSections: {},
};
const JobContext = createContext(initialState);

export function JobDataProvider({ children }: JobDataProviderProps) {
  const { age, jobId, jobType, tab = '' } = useRouteParams();
  const isNewJob = jobId === 'new';
  const [areDefaultValuesSet, setAreDefaultValuesSet] = useState(false);
  const clientIdPreference = usePreference('sys.mid', '');
  const [fields, setFields] = useState<FieldsTransformed>({});
  const [fieldLayouts, setFieldLayouts] = useState<FieldLayouts>({});
  const hasEditRights = useAppSelector((state) => state.user.rights.edit).includes(
    `job-${jobType}`,
  );
  const [preview, setPreview] = useState<Preview>({});
  const [visibilityOfSections, setVisibilityOfSections] = useState<VisibilityOfSections>({});

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [changeLogicBuilderField] = useChangeLogicBuilderFieldMutation();
  const [steerCascadingSelection] = useSteerCascadingSelectionMutation();
  const [createJob] = useCreateJobMutation();
  const [updateJob] = useUpdateJobMutation();
  const queryParams = useQueryParams();

  const { data: tabs } = useGetTabsQuery({
    age,
    jobId: isNewJob ? undefined : jobId,
    jobType,
  });

  const useFormReturn = useForm();
  const mode = queryParams.get('mode') || undefined;
  const copyFromJobId = queryParams.get('copyFromJobId') || undefined;
  const copyFromJobType = queryParams.get('copyFromJobType') || undefined;
  const copyToProject = queryParams.get('copyToProject') || undefined;
  const copyToProjectType = queryParams.get('copyToProjectType') || undefined;

  const {
    formState,
    getValues,
    handleSubmit: reactHookFormHandleSubmit,
    reset,
    setValue,
  } = useFormReturn;

  const { data: jobData, isFetching: isJobFetching } = useGetJobQuery({
    age,
    jobId,
    jobType,
    mode: mode === 'copy-in-project' ? 'copy' : mode,
    originJobId: copyFromJobId,
    originJobType: copyFromJobType,
  });

  const rtkQueryFields = jobData?.fields;
  const values = jobData?.values;
  const rtkQueryVisibilityOfSections = jobData?.visibilityOfSections;

  const isFieldLayoutLoadedForCurrentTab = !!fieldLayouts[tab];

  const areAllFieldsDisabled = useMemo(() => {
    function areAllSectionFieldsDisabled({
      fields: fieldLayoutFields,
      sections,
      table,
    }: Section): boolean {
      return (
        (fieldLayoutFields?.every(({ alias }) => !!fields?.[alias]?.isDisabled) ?? true) &&
        (table?.rows.every(
          ({ fixedFields, scrollableFields }) =>
            fixedFields.every((alias) => !!fields?.[alias]?.isDisabled) &&
            scrollableFields.every((alias) => !!fields?.[alias]?.isDisabled),
        ) ??
          true) &&
        (sections?.every((section) => areAllSectionFieldsDisabled(section)) ?? true)
      );
    }

    if (!isJobFetching && !!fields && isFieldLayoutLoadedForCurrentTab) {
      return fieldLayouts[tab].sections.every((section) => areAllSectionFieldsDisabled(section));
    } else return false;
  }, [fieldLayouts[tab], fields, isJobFetching]);

  const codesOfFormTabs = useMemo(
    () =>
      tabs?.reduce(
        (codesOfFormTabs, { code, link }) =>
          code === 'job' || code === 'det' || link.includes('/form-')
            ? [...codesOfFormTabs, code]
            : codesOfFormTabs,
        [] as string[],
      ),
    [tabs],
  );

  async function handleChangeLogicBuilderField(changedFieldAlias: string) {
    const values = getValues();

    delete values.emptyJobForm;
    Object.keys(values).forEach((alias) => {
      values[alias] = extractFieldValue(values[alias]);
    });

    await changeLogicBuilderField({
      action: `job.${jobType}.${isNewJob ? 'snew' : 'sedt'}`,
      age,
      changedFieldAlias,
      code: `form.${jobType}.handler`,
      event: 'form.change',
      jobId,
      jobType,
      values,
    })
      .unwrap()
      .then(({ fields, sections, values: logicBuilderValues }) => {
        setFields((previousFields) => ({ ...previousFields, ...fields }));
        setVisibilityOfSections((previousVisibilityOfSections) => ({
          ...previousVisibilityOfSections,
          ...sections,
        }));
        Object.entries(logicBuilderValues).forEach(([alias, value]) => {
          // Value changes must be marked as dirty
          // so that they aren't overwritten with original values when submitting the form.
          setValue(alias, value, { shouldDirty: true });
        });
        clearValuesOfMismatchedSelectFields(fields, logicBuilderValues, values);
      });
  }

  function clearValuesOfMismatchedSelectFields(
    fields: FieldsTransformed,
    logicBuilderValues: FieldValues,
    originalValues: FieldValues,
  ) {
    Object.keys(fields).forEach((alias) => {
      const options = fields[alias].fieldData;

      if (isSelectField(fields[alias].type) && Array.isArray(options)) {
        const value = logicBuilderValues[alias]?.toString() || originalValues[alias]?.toString();
        const isValueAnOption = options.some((option) => option.value.toString() === value);

        // Value changes must be marked as dirty
        // so that they aren't overwritten with original values when submitting the form.
        if (!isValueAnOption) setValue(alias, null, { shouldDirty: true });
      }
    });
  }

  async function handleChangeSteeredField(fieldName: string) {
    if (!fields[fieldName]?.steer?.children?.length) {
      return;
    }

    let itemsToSteer = fields[fieldName]?.steer.children || [];
    const steeredFields: Aliases = [];
    const resultArray: Promise<Array<{ label: string; value: string }>>[] = [];

    do {
      const formValues = getValues();
      let newChildren: Aliases = [];

      itemsToSteer.forEach((child) => {
        if (fields[child] && fields[child]?.steer.children) {
          newChildren = [...newChildren, ...(fields[child]?.steer.children || [])];
        }

        resultArray.push(
          steerCascadingSelection({
            child,
            parents: (fields[child]?.steer.parents || []).map((parent) => ({
              alias: parent,
              value: extractFieldValue(formValues[parent]),
            })),
          }).unwrap(),
        );

        steeredFields.push(child);
        setValue(child, '');
      });

      itemsToSteer = newChildren;
    } while (itemsToSteer.length);

    const result = await Promise.all(resultArray);
    const newElements: Record<string, Array<{ label: string; value: string }>> = {};

    result.forEach((value, index) => {
      if (fields[steeredFields[index]] && fields[steeredFields[index]].fieldData) {
        newElements[steeredFields[index]] = value;
      }
    });

    setFields((previousFields) => ({
      ...previousFields,
      ...Object.entries(newElements).reduce<FieldsTransformed>((acc, [key, value]) => {
        acc[key] = {
          ...previousFields[key],
          fieldData: value,
        };

        return acc;
      }, {}),
    }));
  }

  async function onSubmit(formData: FieldValues) {
    const formValues = { ...values, ...filterFormData(formState.dirtyFields, fields, formData) };

    if (isNewJob) {
      await createJob({
        age,
        formValues,
        jobType,
        ...(mode === 'sub' && {
          parentJobId: copyFromJobId,
          parentJobType: copyFromJobType,
        }),
        ...(mode === 'copy-in-project' && {
          parentJobId: copyToProject,
          parentJobType: copyToProjectType,
        }),
      })
        .unwrap()
        .then(({ jobId }) => {
          navigate(`/jobs-job-${jobType}/${jobId}/job`);
          dispatch(
            openWaveSnack({
              message: `${jobId} was successfully created`,
              type: 'success',
            }),
          );
          reset({ ...getValues() });
        })
        .catch(({ message }) =>
          dispatch(
            openWaveSnack({
              message,
              type: 'error',
            }),
          ),
        );
    } else {
      if (Object.keys(formState.dirtyFields).length) {
        await updateJob({
          age,
          formValues,
          jobId,
          jobType,
          webStatus: (values as OtherJobValues).webstatus,
        })
          .unwrap()
          .then(() => {
            dispatch(
              openWaveSnack({
                message: `${jobId} was successfully updated`,
                type: 'success',
              }),
            );

            dispatch(jobApi.util.invalidateTags(['Job']));
            dispatch(jobFormApi.util.invalidateTags(['Steps']));
            dispatch(jobFormApi.util.invalidateTags(['StartApprovalData']));
            dispatch(workflowApi.util.invalidateTags(['Deadline', 'Workflow']));
            reset({ ...getValues() });
          })
          .catch(({ message }) =>
            dispatch(
              openWaveSnack({
                message,
                type: 'error',
              }),
            ),
          );
      }
    }
  }

  useEffect(() => {
    if (codesOfFormTabs === undefined) return;

    Promise.all(
      codesOfFormTabs.map(async (tabCode) => {
        const layoutPath = `/src/installations/${import.meta.env.VITE_INSTALLATION}/client_${
          clientIdPreference.value
        }/form/${jobType}/${tabCode}Layout.js`;

        return installationModules[layoutPath]();
      }),
    )
      .then((responses: Array<any>) => {
        const { fieldLayouts, visibilityOfSections } = responses.reduce(
          (
            {
              fieldLayouts,
              visibilityOfSections,
            }: { fieldLayouts: FieldLayouts; visibilityOfSections: VisibilityOfSections },
            { fieldLayout }: { fieldLayout: FieldLayout },
            index,
          ) => {
            function addFieldLayout(tabCode: string, fieldLayout: FieldLayout) {
              fieldLayouts[tabCode] = fieldLayout;
            }

            function addVisibilityOfSections(fieldLayout: FieldLayout) {
              fieldLayout.sections.forEach(({ code }: { code: string }) => {
                visibilityOfSections[code] = 'show';
              });
            }

            const associatedTabCode = codesOfFormTabs[index];

            addFieldLayout(associatedTabCode, fieldLayout);
            addVisibilityOfSections(fieldLayout);

            return { fieldLayouts, visibilityOfSections };
          },
          { fieldLayouts: {}, visibilityOfSections: {} },
        );

        setFieldLayouts(fieldLayouts);
        setVisibilityOfSections(visibilityOfSections);
      })
      .catch((error) =>
        dispatch(
          openWaveSnack({
            message: (error as Error).message,
            type: 'error',
          }),
        ),
      );
  }, [codesOfFormTabs]);

  useEffect(() => {
    if (rtkQueryFields) setFields(rtkQueryFields);

    if (!isNewJob && values && values.preview) setPreview(values.preview as Preview);

    if (rtkQueryVisibilityOfSections) {
      setVisibilityOfSections((previousVisibilityOfSections) => ({
        ...previousVisibilityOfSections,
        ...rtkQueryVisibilityOfSections,
      }));
    }

    if (fieldLayouts && rtkQueryFields && values) {
      reset(getDefaultValues(fieldLayouts, rtkQueryFields, values as FieldValues));
      setAreDefaultValuesSet(true);
    }
  }, [fieldLayouts, rtkQueryVisibilityOfSections, rtkQueryFields, values]);

  return (
    <JobContext.Provider
      value={{
        age,
        areAllFieldsDisabled,
        areDefaultValuesSet,
        fieldLayout: fieldLayouts[tab],
        fields,
        handleChangeLogicBuilderField,
        handleChangeSteeredField,
        handleSubmit: reactHookFormHandleSubmit(onSubmit),
        hasEditRights,
        isJobFetching,
        isNewJob,
        jobType,
        preview,
        setFields,
        values,
        visibilityOfSections,
      }}
    >
      <FormProvider {...useFormReturn}>{children}</FormProvider>
    </JobContext.Provider>
  );
}

export function useJobContext() {
  return useContext(JobContext);
}
