import { Fragment, ReactNode } from 'react';

import {
  Box,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Skeleton,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { Folders } from 'src/features/JobFiles/JobFiles.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { INPUT_HEIGHT } from 'src/utilities/constants';
import { useExpand } from 'src/utilities/hooks';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';
import { parseArrayToLogicalObject } from 'src/utilities/parsers';

type FileExplorerProps = {
  folders: Folders;
  onFolderSelected: (code: string, pathName: string, age?: ExpandedAges, src?: string) => void;
  selectedFolder: string;
  areFoldersLoading: boolean;
  areFilesLoading: boolean;
  children: ReactNode;
  source?: 'files' | 'compare';
};

export function FileExplorer({
  areFilesLoading,
  areFoldersLoading,
  children,
  folders,
  onFolderSelected,
  selectedFolder,
  source = 'files',
}: FileExplorerProps) {
  const { expandedItems, toggleExpandItem } = useExpand(parseArrayToLogicalObject(folders, 'code'));
  const hasSomeSubItems = folders.some(({ subItems }) => subItems && subItems.length);

  function renderFolders() {
    if (areFoldersLoading) {
      return (
        <>
          {[...Array(3)].map((_, index) => (
            <Box key={index} p={0.5}>
              <Skeleton height={INPUT_HEIGHT} variant="rounded" />
            </Box>
          ))}
        </>
      );
    }

    return (
      <Box component={List} p={0}>
        {folders.map(
          ({ code, file_count: fileCount, name, path_name: pathName, subItems = [] }) => (
            <Fragment key={code}>
              <ListItem
                disablePadding
                secondaryAction={<Typography variant="body2">{fileCount}</Typography>}
              >
                <ListItemButton
                  disabled={!fileCount && source !== 'files'}
                  onClick={() =>
                    subItems.length > 0 ? toggleExpandItem(code) : onFolderSelected(code, pathName)
                  }
                  selected={selectedFolder === pathName}
                  sx={{ pl: !subItems.length && hasSomeSubItems ? 5.5 : 1.5 }}
                >
                  {subItems.length ? (
                    <Box component={ListItemIcon} minWidth={0} mr={1}>
                      <WaveIcon code={`expand-${expandedItems[code] ? 'less' : 'more'}`} />
                    </Box>
                  ) : null}

                  <Box component={ListItemIcon} minWidth={0} mr={1}>
                    <WaveIcon code="compare-files-folder-open" />
                  </Box>

                  <ListItemText primary={name} primaryTypographyProps={{ variant: 'body2' }} />
                </ListItemButton>
              </ListItem>

              {subItems.length ? (
                <Collapse in={expandedItems[code] || false} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {subItems.map(
                      (
                        { age, code, file_count: fileCount, name, path_name: pathName, src },
                        index,
                      ) => (
                        <ListItem
                          disablePadding
                          key={index}
                          secondaryAction={<Typography variant="body2">{fileCount}</Typography>}
                        >
                          <ListItemButton
                            onClick={() => onFolderSelected(code, pathName, age, src)}
                            selected={selectedFolder === pathName}
                            sx={{ pl: 7.5 }}
                          >
                            <Box component={ListItemIcon} minWidth={0} mr={1}>
                              <WaveIcon code="compare-files-folder-open" />
                            </Box>

                            <ListItemText
                              primary={name}
                              primaryTypographyProps={{ variant: 'body2' }}
                            />
                          </ListItemButton>
                        </ListItem>
                      ),
                    )}
                  </List>
                </Collapse>
              ) : null}
            </Fragment>
          ),
        )}
      </Box>
    );
  }

  return (
    <Box component={Paper} display="flex" elevation={3} flexDirection="column" flexGrow={1}>
      <Grid container flexGrow={1}>
        <Grid borderRadius="4px 0 0 4px" overflow="hidden" xs={3}>
          {renderFolders()}
        </Grid>

        <Divider flexItem orientation="vertical" />

        <Grid xs>
          {areFilesLoading ? (
            <>
              {[...Array(3)].map((_, index) => (
                <Box key={index} p={1} px={1.5}>
                  <Skeleton height={36} variant="rounded" />
                </Box>
              ))}
            </>
          ) : (
            children
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
