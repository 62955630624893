/* eslint-disable quotes */
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';

import { JobTasks, StatusChangeTasks } from 'src/features/JobsTable/utilities/hooks/useJobs';
import { AutoComplete } from 'src/features/Tasks/components/AutoComplete';
import { TeamMembers } from 'src/pages/Dashboard/Dashboard.service';
import { usePreference, usePreferencePrefix } from '../../utilities/hooks';
import { BarChart } from './components/BarChart';
import { PieChart } from './components/PieChart';
import { Switch } from './components/Switch';

function calculateColumnSizes(...params: any) {
  const numberOfCharts = params.filter(Boolean).length;
  const barChartWeight = 1.5;
  const pieChartWeight = 0.75;
  const totalColumns = 12;

  function calculateSizeFromWeight(weight: number) {
    return (totalColumns / numberOfCharts) * (numberOfCharts === 3 ? weight : 1);
  }

  return {
    approvalTasksColumns: calculateSizeFromWeight(pieChartWeight),
    jobTasksColumns: calculateSizeFromWeight(barChartWeight),
    statusChangeTasksColumns: calculateSizeFromWeight(pieChartWeight),
  };
}

type TasksProps = {
  approvalTasks: JobTasks;
  isLoadingUsers: boolean;
  jobTasks: JobTasks;
  isLoading: boolean;
  onSetTeammateID: Dispatch<SetStateAction<number>>;
  statusChangeTasks: StatusChangeTasks;
  teammateID: number;
  users: TeamMembers;
};

export function Tasks({
  approvalTasks,
  isLoading,
  isLoadingUsers,
  jobTasks,
  onSetTeammateID,
  statusChangeTasks,
  teammateID,
  users,
}: TasksProps) {
  const { t } = useTranslation();
  const { preferencePrefix } = usePreferencePrefix();

  const approvalTaskPreference = usePreference(`${preferencePrefix}.show-approval-tasks`, '1');
  const jobTaskPreference = usePreference(`${preferencePrefix}.show-job-tasks`, '1');
  const statusChangeTasksPreference = usePreference(
    `${preferencePrefix}.show-status-change-tasks`,
    '1',
  );

  const approvalTasksID = 'approvalTasks';
  const approvalTasksLabel = t('lib.approvals_task', 'Approval Tasks');
  const jobTasksID = 'jobTasks';
  const jobTasksLabel = t('tasks_job_label', 'Job Tasks');
  const statusChangeTasksID = 'statusChangeTasks';
  const statusChangeTasksLabel = t('tasks_status_change_label', 'Status Change Tasks');
  const canShowApprovalTasks = approvalTaskPreference.value === '1';
  const canShowJobTasks = jobTaskPreference.value === '1';
  const canShowStatusChangeTasks = statusChangeTasksPreference.value === '1';

  const { approvalTasksColumns, jobTasksColumns, statusChangeTasksColumns } = calculateColumnSizes(
    canShowApprovalTasks,
    canShowJobTasks,
    canShowStatusChangeTasks,
  );

  function handleSwitchChange({ target: { checked, id: switchID } }: { target: any }) {
    const value = checked === true ? '1' : '0';

    if (switchID === approvalTasksID) approvalTaskPreference.set(value);
    else if (switchID === jobTasksID) jobTaskPreference.set(value);
    else if (switchID === statusChangeTasksID) statusChangeTasksPreference.set(value);
  }

  function handleUserChange({ target: { value } }: { target: any }) {
    onSetTeammateID(parseInt(value));
  }

  return (
    <Card>
      <CardHeader
        classes={{ content: 'mx-10' }}
        className="p-5 primary-bg white-txt"
        title={<Typography component="p">{t('lib.tasks', 'Tasks')}</Typography>}
      />

      <CardContent>
        <Grid alignItems="center" container margin={0} spacing={1}>
          <Grid item position="relative" xs={3}>
            <AutoComplete
              disabled={isLoadingUsers}
              label={t('tasks_teammates_field', "View Teammate's Tasks")}
              onChange={handleUserChange}
              options={users}
              value={teammateID}
            />

            {isLoadingUsers ? <CircularProgress className="buttonProgress" size={24} /> : null}
          </Grid>

          <Grid container item justifyContent="space-around" xs={9}>
            <Grid item position="relative">
              <Switch
                disabled={approvalTaskPreference.isLoading}
                id="approvalTasks"
                label={approvalTasksLabel}
                onChange={handleSwitchChange}
                value={canShowApprovalTasks}
              />

              {approvalTaskPreference.isLoading ? (
                <CircularProgress className="buttonProgress" size={24} />
              ) : null}
            </Grid>

            <Grid item position="relative">
              <Switch
                disabled={jobTaskPreference.isLoading}
                id="jobTasks"
                label={jobTasksLabel}
                onChange={handleSwitchChange}
                value={canShowJobTasks}
              />

              {jobTaskPreference.isLoading ? (
                <CircularProgress className="buttonProgress" size={24} />
              ) : null}
            </Grid>

            <Grid item position="relative">
              <Switch
                disabled={statusChangeTasksPreference.isLoading}
                id="statusChangeTasks"
                label={statusChangeTasksLabel}
                onChange={handleSwitchChange}
                value={canShowStatusChangeTasks}
              />

              {statusChangeTasksPreference.isLoading ? (
                <CircularProgress className="buttonProgress" size={24} />
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid container marginTop="10px">
          {canShowApprovalTasks && (
            <Grid container direction="column" item xs={approvalTasksColumns}>
              <PieChart isLoading={isLoading} pieces={approvalTasks} title={approvalTasksLabel} />
            </Grid>
          )}

          {canShowJobTasks && (
            <Grid container direction="column" item xs={jobTasksColumns}>
              <PieChart isLoading={isLoading} pieces={jobTasks} title={jobTasksLabel} />
            </Grid>
          )}

          {canShowStatusChangeTasks && (
            <Grid container direction="column" item xs={statusChangeTasksColumns}>
              <BarChart
                bars={statusChangeTasks}
                hoverBarLabel={t('total_tasks', 'Total Tasks')}
                isLoading={isLoading}
                title={statusChangeTasksLabel}
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
