import { Checkbox, IconButton, Radio, TableCell, TableRow, Typography } from '@mui/material';

import { TableButtonCell } from 'src/components/TableButtonCell';
import { Cell } from 'src/features/JobsTable/components/Cell/Cell';
import { CopyJobDialog } from 'src/features/JobsTable/components/CopyJobDialog';
import { useRow } from 'src/features/JobsTable/components/Row/useRow';
import { StyledLink } from 'src/features/JobsTable/components/StyledLink/StyledLink';
import { SubTable } from 'src/features/JobsTable/components/SubTable';
import {
  Assignment as AssignmentJobsTable,
  Common as CommonJobsTable,
  Dashboard as DashboardJobsTable,
  Main as MainJobsTable,
  WithoutConfirmationDialog as JobsTableWithoutConfirmationDialog,
  WithoutExpandableRows as JobsTableWithoutExpandableRows,
  WithoutSelectableRow as JobsTableWithoutSelectableRow,
} from 'src/features/JobsTable/JobsTable';
import { Deadline, RowTransformed } from 'src/features/JobsTable/JobsTable.service';
import { WaveIcon } from 'src/features/WaveIcon';

import { IsDense } from 'src/features/JobsTable/types';

type Common = {
  deadline?: Deadline;
  row: RowTransformed;
  rowId: number;
} & Pick<CommonJobsTable, 'columns'>;
type IsNotSub = { isSubRow?: never } & Pick<CommonJobsTable, 'totalRowCount'> & IsDense;
type WithActionableRow = {
  canCopyToSomeJobType: boolean;
  canTablePossiblyHaveADeletableRow: boolean;
};
type WithoutActionableRow = {
  canCopyToSomeJobType?: never;
  canTablePossiblyHaveADeletableRow?: never;
};
type WithoutHeaderSelection = 'onToggleSelectAllRows' | 'setSelectedRows';
type Main = IsNotSub & WithActionableRow & Omit<MainJobsTable, WithoutHeaderSelection>;
type Dashboard = IsNotSub & WithoutActionableRow & Omit<DashboardJobsTable, WithoutHeaderSelection>;
type Assignment = IsNotSub & WithoutActionableRow & Omit<AssignmentJobsTable, 'setSelectedRows'>;
type Sub = {
  isDense?: never;
  isSubRow: boolean;
  totalRowCount?: never;
} & WithoutActionableRow &
  JobsTableWithoutConfirmationDialog &
  JobsTableWithoutExpandableRows &
  JobsTableWithoutSelectableRow;

export type RowProps = Common & (Main | Dashboard | Assignment | Sub);

export function Row({
  canCopyToSomeJobType = false,
  canTablePossiblyHaveADeletableRow = false,
  columns,
  deadline = {},
  idsOfExpandedJobs = [],
  isDense = true,
  isSubRow = false,
  jobIdsWithSubJobs = [],
  onClickRowCheckbox,
  onClickRowRadio,
  onOpenConfirmationDialog,
  row,
  rowId,
  selectedRows = [],
  setIdsOfExpandedJobs,
  totalRowCount = 0,
}: RowProps) {
  const {
    accumulativeRowId,
    areCopyJobOptionsFetching,
    arrowCode,
    canDelete,
    canPossiblyHaveActions,
    cellPaddingY,
    copyCode,
    copyJobOptions,
    deadlineStyle,
    deleteCode,
    filler,
    handleClickCheckbox,
    handleClickExpandCollapse,
    handleClickRow,
    handleCloseCopyJobDialog,
    handleDeleteJob,
    handleOpenCopyJobDialog,
    isCopyJobConfirmationDialogOpen,
    isRowExpanded,
    isSelected,
    jobLink,
    oddRowStyle,
    rowSelectionColumnSpan,
    shouldHaveCheckbox,
    shouldHaveExpandCollapseButton,
    shouldHaveExpandCollapseColumn,
    status,
  } = useRow({
    canTablePossiblyHaveADeletableRow,
    deadline,
    idsOfExpandedJobs,
    isDense,
    isSubRow,
    jobIdsWithSubJobs,
    onClickRowCheckbox,
    onOpenConfirmationDialog,
    row,
    rowId,
    selectedRows,
    setIdsOfExpandedJobs,
    totalRowCount,
  });

  return (
    <>
      <TableRow
        hover
        onClick={handleClickRow}
        selected={isSelected}
        sx={{ cursor: 'pointer' }}
        {...oddRowStyle}
      >
        <TableCell
          sx={{ bgcolor: filler.main, width: 40, ...(deadlineStyle && { deadlineStyle }) }}
        >
          <StyledLink href={jobLink}>
            <Typography color={filler.contrastText} variant="body2">
              {accumulativeRowId}
            </Typography>
          </StyledLink>
        </TableCell>

        {shouldHaveExpandCollapseColumn ? (
          <>
            {shouldHaveExpandCollapseButton ? (
              <TableButtonCell paddingY={cellPaddingY}>
                <IconButton onClick={handleClickExpandCollapse}>
                  <WaveIcon className="primary-txt" code={arrowCode} />
                </IconButton>
              </TableButtonCell>
            ) : (
              <TableButtonCell />
            )}
          </>
        ) : null}

        <TableButtonCell
          muiTableCellProps={{ colSpan: rowSelectionColumnSpan }}
          paddingY={cellPaddingY}
          {...(isSubRow && { muiTableCellProps: { sx: { bgcolor: filler.main } } })}
        >
          <>
            {shouldHaveCheckbox ? (
              <Checkbox checked={isSelected} onClick={handleClickCheckbox} size="small" />
            ) : null}

            {onClickRowRadio ? (
              <Radio checked={isSelected} onClick={onClickRowRadio} value={row.jobId} />
            ) : null}
          </>
        </TableButtonCell>

        {canPossiblyHaveActions ? (
          <>
            {canCopyToSomeJobType ? (
              <TableButtonCell paddingY={cellPaddingY}>
                <IconButton onClick={handleOpenCopyJobDialog}>
                  <WaveIcon className="primary-txt" code={copyCode} />
                </IconButton>
              </TableButtonCell>
            ) : null}

            {canDelete ? (
              <TableButtonCell paddingY={cellPaddingY}>
                <IconButton onClick={handleDeleteJob}>
                  <WaveIcon className="error-txt" code={deleteCode} />
                </IconButton>
              </TableButtonCell>
            ) : null}
          </>
        ) : null}

        {columns.map(({ fieldAlias }) => (
          <Cell
            deadline={deadline}
            fieldAlias={fieldAlias}
            isDense={isDense}
            isSubRow={isSubRow}
            jobLink={jobLink}
            key={fieldAlias}
            paddingY={cellPaddingY}
            row={row}
            status={status}
          />
        ))}
      </TableRow>

      {isRowExpanded ? (
        <SubTable
          isExpanded={isRowExpanded}
          numberOfParentColumns={columns.length}
          parentRowJobId={row.jobId}
          parentRowJobType={row.jobType}
        />
      ) : null}

      {isCopyJobConfirmationDialogOpen ? (
        <CopyJobDialog
          areOptionsFetching={areCopyJobOptionsFetching}
          copyJobOptions={copyJobOptions}
          jobId={row.jobId}
          jobType={row.jobType}
          onClose={handleCloseCopyJobDialog}
        />
      ) : null}
    </>
  );
}
