import { useParams } from 'react-router-dom';

export type Age = { age?: ExpandedAges };
export type ExpandedAges = BasicAge | 'global' | 'hom';
export type BasicAge = 'arc' | 'job';
export type JobIdString = { jobId?: string };
export type JobType = { jobType?: string };
export type Tab = { tab?: string };

export function useRouteParams(parameters?: {
  defaultJobId?: number | string;
  forcedAge?: BasicAge;
}) {
  const params: {
    secretKey?: string;
    tab?: string;
    tabCode?: string;
    type?: string;
  } & Age &
    JobIdString &
    JobType = useParams();
  const { age, jobId = parameters?.defaultJobId, jobType, secretKey, tab, tabCode, type } = params;

  return {
    age: parameters?.forcedAge || age,
    jobId: typeof jobId === 'string' && jobId !== 'new' ? parseInt(jobId) : jobId,
    jobType: tab === 'assign' ? 'pro' : jobType,
    secretKey,
    tab,
    tabCode,
    type,
  };
}
