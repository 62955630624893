import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { t } from 'i18next';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  IconProps,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';

import { TextField } from 'src/components/RHF/TextField';
import { NutritionLayout } from 'src/features/Approvals/components/MasterContent/components/NutritionLayout';
import { StandardLayout } from 'src/features/Approvals/components/MasterContent/components/StandardLayout';
import { WaveDialogTitle } from '../../../../components/WaveDialogTitle';
import { WaveIcon } from '../../../WaveIcon';

type MasterContentProps = {
  isMasterContentOpen: boolean;
  onClose: () => void;
};

type FormData = {
  [x: string]: Record<string, string>;
};

export function MasterContent({ isMasterContentOpen, onClose }: MasterContentProps) {
  const categories = [
    {
      alias: 'productDescription1',
      id: '1',
      layout: 'standard',
      masterContentComment: 'It is a long established fact that a reader will be distracted',
      name: 'Product Description 1',
    },

    {
      alias: 'ingredientsDictionary',
      id: '3',
      layout: 'RTF',
      masterContentComment: 'It is a testing RTF Text',
      name: 'Ingredients Dictionary',
    },
    {
      alias: 'nutrition',
      id: '4',
      layout: 'nutrition',
      name: 'Nutrition',
    },
    {
      alias: 'summary',
      id: '5',
      layout: 'comment',
      name: 'Summary',
    },
  ];

  const ICON_COLORS: Record<string, IconProps['color']> = {
    amendment: 'error',
    approval: 'success',
    conditional: 'warning',
    unactioned: 'primary',
  };

  const {
    control,
    formState: { isDirty },
    getValues,
    handleSubmit,
    reset,
    resetField,
    setValue,
  } = useForm({ mode: 'onChange' });

  const [shouldHideReviewedContent, setShouldHideReviewedContent] = useState(true);
  const [accordionSections, setAccordionSections] = useState<Record<string, boolean>>();
  const [shouldCollapseExpandAll, setShouldCollapseExpandAll] = useState(false);
  const [hideReviewContent, setHideReviewContent] = useState<string[]>();

  async function onSubmit(data: FormData) {
    let collapseContent: Record<string, boolean> = {};
    const reviewedContent = Object.keys(data).filter((key) => data[key]?.type);
    const expandNextReviewedContent =
      categories[
        categories.findIndex((el) => el.alias === reviewedContent[reviewedContent.length - 1]) + 1
      ].alias;

    reviewedContent.map((content) => {
      return (collapseContent = { ...collapseContent, [content]: false });
    });

    setAccordionSections({
      ...accordionSections,
      ...collapseContent,
      [expandNextReviewedContent]: true,
    });
    setHideReviewContent(reviewedContent);
  }

  function handleToggleCollapseExpandAll() {
    setShouldCollapseExpandAll(!shouldCollapseExpandAll);
    categories.map((category) => {
      setAccordionSections((prev) => ({
        ...prev,
        [`${category.alias}`]: !shouldCollapseExpandAll,
      }));
    });
  }

  function handleAccordionChange(alias: string) {
    setAccordionSections({
      ...accordionSections,
      [alias]: !accordionSections?.[alias],
    });
  }

  function renderCategories() {
    return categories.map((category) => {
      const reviewedAction = getValues(`${category.alias}.type`);

      return (
        <Accordion
          expanded={accordionSections?.[category.alias]}
          key={category.id}
          onChange={() => handleAccordionChange(category.alias)}
          sx={{
            display: `${
              hideReviewContent?.includes(category.alias) && shouldHideReviewedContent
                ? 'none'
                : 'block'
            }`,
          }}
        >
          <AccordionSummary
            sx={{ backgroundColor: 'filler.main', color: 'primary.main', padding: 1 }}
          >
            <WaveIcon
              code={reviewedAction || 'edit'}
              color={ICON_COLORS[reviewedAction] || ('primary' as IconProps['color'])}
              fontSize="small"
            />

            <Typography fontWeight={500} paddingLeft={1}>
              {category.name}
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            {category.layout === 'standard' || category.layout === 'RTF' ? (
              <StandardLayout
                accordionSections={accordionSections}
                alias={category.alias}
                control={control}
                getValues={getValues}
                isDirty={isDirty}
                layout={category.layout}
                masterContentComment={category.masterContentComment}
                resetField={resetField}
                setAccordionSections={setAccordionSections}
                setValue={setValue}
              />
            ) : category.layout === 'nutrition' ? (
              <NutritionLayout alias={category.alias} control={control} setValue={setValue} />
            ) : (
              <TextField
                control={control}
                label={t('lib.msg', 'Comment')}
                name={`${category.alias}.comment`}
                type="memo"
              />
            )}
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  useEffect(() => {
    categories.map((category, index) => {
      setAccordionSections((prev) => ({
        ...prev,
        [`${category.alias}`]: index === 0 ? true : false,
      }));
    });
  }, []);

  useEffect(() => {
    setShouldCollapseExpandAll(
      !Object.values(accordionSections ?? '').includes(false) ? true : false,
    );
  }, [accordionSections]);

  return (
    <Dialog
      fullWidth={true}
      maxWidth="md"
      onClose={() => {
        onClose();
        reset();
      }}
      open={isMasterContentOpen}
    >
      <WaveDialogTitle
        onClose={onClose}
        title={t('apl.phrase.content.title', 'Review Master Content')}
      />

      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid alignItems="center" container justifyContent="space-between">
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shouldHideReviewedContent}
                    onClick={() => setShouldHideReviewedContent(!shouldHideReviewedContent)}
                  />
                }
                label={t('hide_reviewed_content', 'Hide Reviewed Content')}
              />
            </Grid>

            <Grid>
              <Button
                onClick={handleToggleCollapseExpandAll}
                startIcon={
                  <WaveIcon code={shouldCollapseExpandAll ? 'expand-less' : 'expand-more'} />
                }
              >
                {shouldCollapseExpandAll ? 'Collapse All' : 'Expand All'}
              </Button>
            </Grid>
          </Grid>

          {renderCategories()}
        </DialogContent>

        <DialogActions className="space-between">
          <Button color="warning" onClick={onClose}>
            <Trans i18nKey="lib.dialog.cancel">Cancel</Trans>
          </Button>

          <Button type="submit">Complete Review</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
