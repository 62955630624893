import { useTranslation } from 'react-i18next';

import { useNavigate, useParams } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Button, Container, Divider, Stack } from '@mui/material';

import { ActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs';
import { BreadCrumbs } from 'src/components/BreadCrumbs';
import { FilterDrawer } from 'src/components/FilterDrawer';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { jobFormApi } from 'src/features/JobForm/JobForm.service';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { useUpdateAssignToProjectMutation } from 'src/pages/AssignToProject/AssignToProject.service';
import { jobApi } from 'src/pages/Job/Job.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useRouteParams } from 'src/utilities/hooks';
import { CONTAINER_STYLES, STACK_STYLES } from 'src/utilities/stylesOfJobs';

export function AssignToProject() {
  const dispatch = useAppDispatch();
  const {
    areActiveFiltersVisible,
    areJobsFetching,
    columns,
    deadlines,
    handleChangePageSearch,
    handleClickRowRadio,
    handleToggleActiveFiltersVisibility,
    rows,
    searchTerm,
    selectedRows,
    setAreActiveFiltersVisible,
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  } = useJobs();

  const navigate = useNavigate();
  const { jobId } = useRouteParams();
  const { jobType: unAlteredJobType } = useParams();

  const [updateAssignToProject, { isLoading: isUpdateAssignToProjectLoading }] =
    useUpdateAssignToProjectMutation();
  const { t: translate } = useTranslation();

  function handleCancel() {
    navigate(`/jobs-job-${unAlteredJobType}/${jobId}/job`);
  }

  async function handleConfirm() {
    updateAssignToProject({
      assignToJobId: selectedRows[0],
      assignToJobType: 'pro',
      jobId,
      jobType: unAlteredJobType,
    })
      .unwrap()
      .then((message) => {
        dispatch(
          openWaveSnack({
            message,
            type: 'success',
          }),
        );
        dispatch(jobFormApi.util.invalidateTags(['Actions', 'RelatedJobs']));
        dispatch(jobApi.util.invalidateTags(['Job']));
        navigate(`/jobs-job-${unAlteredJobType}/${jobId}/job`);
      })
      .catch(() => {
        setSelectedRows([]);
      });
  }

  return (
    <>
      <BreadCrumbs />

      <Stack direction="row" gap={1} justifyContent="end" m={1}>
        <Button color="warning" onClick={handleCancel}>
          {translate('lib.cancel')}
        </Button>

        <WaveTooltip
          body={selectedRows.length === 0 ? 'Please select a project.' : ''}
          component={
            <LoadingButton
              disabled={!selectedRows.length}
              loading={isUpdateAssignToProjectLoading}
              onClick={handleConfirm}
              variant="contained"
            >
              {translate('lib.confirm')}
            </LoadingButton>
          }
          placement="top"
          type="simple"
        />
      </Stack>

      <Divider />

      <Container disableGutters maxWidth={false} sx={CONTAINER_STYLES}>
        <ActionBar
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          searchTerm={searchTerm}
          selectedRows={selectedRows}
        />

        <ActiveFiltersOfJobs
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <Stack {...STACK_STYLES}>
        <FilterDrawer
          areActiveFiltersVisible={areActiveFiltersVisible}
          handleToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          parentLocation="assign-to-project"
        />

        <JobsTable
          areActiveFiltersVisible={areActiveFiltersVisible}
          areJobsFetching={areJobsFetching}
          columns={columns}
          deadlines={deadlines}
          onClickRowRadio={handleClickRowRadio}
          rows={rows}
          selectedRows={selectedRows}
          setPageHistory={setPageHistory}
          setSelectedRows={setSelectedRows}
          totalRowCount={totalRowCount}
        />
      </Stack>
    </>
  );
}
