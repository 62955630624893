import { useLocation } from 'react-router-dom';

import { ContentForm } from './components/ContentForm';
import { ContentList } from './components/ContentList';

export function Content() {
  const location = useLocation();

  // Temporal solution until we migrate to react-router-dom 6.5+ and will have optional params
  const path = location.pathname;
  const isCreateOrEditContent = path.includes('/new') || path.includes('/edit');

  return isCreateOrEditContent ? <ContentForm /> : <ContentList />;
}
