import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { NavBar } from '../../components/NavBar';
import { Spinner } from '../../components/Spinner';
import { Job } from '../../pages/Job';
import { Viewer } from '../../pages/Viewer';
import { getExternalRevisorDetails } from '../../store/userSlice';
import { setupRequestInterceptor } from '../../utilities/api';
import { requestInterceptor, saveTokens } from '../../utilities/auth';
import { useAppDispatch, useIsExternalRevisor } from '../../utilities/hooks';
import { useAuthenticateExternalRevisorQuery } from './ExternalRevisor.service';

function hasQueryParams(search: string) {
  return new URLSearchParams(search).toString() !== '';
}

export function ExternalRevisor() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const path = location.pathname.split('/');
  const isExternalRevisor = useIsExternalRevisor();
  const age = isExternalRevisor ? path[3].split('-')[1] : path[1].split('-')[1];
  const jobId = isExternalRevisor ? path[4] : path[2];
  const jobType = isExternalRevisor ? path[3].split('-')[2] : path[1].split('-')[2];
  const tab = isExternalRevisor ? path[5] : path[3];
  const token = isExternalRevisor ? path[2] : '';

  const { data, isError, isSuccess } = useAuthenticateExternalRevisorQuery(
    {
      age,
      jobId,
      jobType,
      token,
    },
    { skip: !isExternalRevisor && tab !== 'external-viewer' },
  );

  useEffect(() => {
    if (isError) {
      navigate('/login');
    } else if (isSuccess) {
      saveTokens(data);
      setupRequestInterceptor(requestInterceptor);
      (async () => {
        await dispatch(getExternalRevisorDetails());
        setIsLoading(false);
      })();
    }
  }, [isError, isSuccess, data]);

  return isLoading ? (
    <Spinner />
  ) : (
    <Box height={'calc(100vh - 288px)'}>
      {hasQueryParams(location.search) ? (
        <Viewer />
      ) : (
        <>
          <NavBar />

          <Job />
        </>
      )}
    </Box>
  );
}
