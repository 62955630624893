import { useTranslation } from 'react-i18next';

import { Box, Typography, useTheme } from '@mui/material';

import { OFFSET_COLUMN_WIDTH } from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { FieldValue } from 'src/pages/Job/Job.service';

type DialogHeaderProps = {
  jobIdValue: FieldValue;
  integrateIdValue: FieldValue;
  totalUpdatedCount: number;
  totalFieldsCount: number;
};

export function DialogHeader({
  integrateIdValue,
  jobIdValue,
  totalFieldsCount,
  totalUpdatedCount,
}: DialogHeaderProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      bgcolor="primary.main"
      display="flex"
      position="sticky"
      sx={{ boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)' }}
      top={0}
      zIndex={100}
    >
      <Box marginLeft={1.5} width={OFFSET_COLUMN_WIDTH}>
        <Typography color={theme.palette.common.white} fontWeight={600}>
          {t('artwork_approval', 'Artwork Approval')}
        </Typography>
      </Box>

      <Box alignItems="center" display="flex" flex="1" marginLeft={1.5} marginRight={5}>
        <Typography color={theme.palette.common.white} fontWeight={600}>
          {`${t('job_id', 'JOB')} (ID ${jobIdValue})`}
        </Typography>
      </Box>

      <Box alignItems="center" display="flex" flex="1">
        <Typography color={theme.palette.common.white} fontWeight={600}>
          {`${t('integrate_id', 'Integrate')} (ID ${integrateIdValue} ) (${
            totalUpdatedCount || totalFieldsCount
          }/${totalFieldsCount})`}
        </Typography>
      </Box>
    </Box>
  );
}
