import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { TokensData } from '../../utilities/auth';

type AuthenticateExternalRevisorRequest = {
  age: string;
  jobId: number | string;
  jobType: string;
  token: string;
};

export const externalRevisorApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    authenticateExternalRevisor: build.query<TokensData, AuthenticateExternalRevisorRequest>({
      query({ age, jobId, jobType, token }) {
        return {
          data: { age, jobid: jobId, src: jobType, token },
          method: 'POST',
          url: '/external-revisor/login',
        };
      },
    }),
  }),
  reducerPath: 'externalRevisorApi',
});

export const { useAuthenticateExternalRevisorQuery } = externalRevisorApi;
