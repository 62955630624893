import { Box, Divider, List, ListItem, ListSubheader, Paper, TableCell } from '@mui/material';

import { Typography } from 'src/components/Typography';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { Approval } from 'src/features/Approvals/Approvals.service';
import { ICON_COLORS } from 'src/features/Approvals/components/DetailsTable/DetailsTable';
import { useApprovalCell } from 'src/features/JobsTable/components/ApprovalCell/useApprovalCell';
import { CellProps } from 'src/features/JobsTable/components/Cell/Cell';
import { StyledLink } from 'src/features/JobsTable/components/StyledLink/StyledLink';
import { SUB_ROW_BORDER_STYLES } from 'src/features/JobsTable/utilities/styles';
import { WaveIcon } from 'src/features/WaveIcon';
import { JobIdRequired } from 'src/pages/Job/Job.service';

import { CommonCellProps, RowJobType } from 'src/features/JobsTable/types';

export type ApprovalCellProps = {
  value: Approval | {};
} & Pick<CellProps, 'status'> &
  CommonCellProps &
  JobIdRequired &
  RowJobType;

export function ApprovalCell({
  isSubRow,
  jobId,
  paddingY,
  rowJobType,
  status,
  value,
}: ApprovalCellProps) {
  const { age, approvals, handleClickCell } = useApprovalCell({
    jobId,
    rowJobType,
    status,
    value,
  });

  return (
    <TableCell
      onClick={handleClickCell}
      sx={{
        paddingY,
        ...(isSubRow && SUB_ROW_BORDER_STYLES),
      }}
    >
      <StyledLink href={`/jobs-${age}-${rowJobType}/${jobId}`}>
        {'approval' in value ? (
          <WaveTooltip
            body={
              <Box component={Paper} elevation={3} maxHeight="25vh" overflow="auto">
                {approvals.map((approval, index) => {
                  const hasMultipleApprovals = approvals.length > 1;
                  const isLastApproval = index === approvals.length - 1;
                  const shouldShowDivider = hasMultipleApprovals && !isLastApproval;

                  return (
                    <List
                      disablePadding
                      key={approval.id}
                      {...(approval.prefix && {
                        subheader: <ListSubheader>{approval.prefix}</ListSubheader>,
                      })}
                    >
                      {approval.details.map(({ id, position, status, user }) => (
                        <ListItem key={id}>
                          <Box
                            code={`job-approvals-revisor-status-${status}`}
                            color={ICON_COLORS[status]}
                            component={WaveIcon}
                            marginRight={1}
                            sx={{ verticalAlign: 'middle' }}
                          />

                          <Typography fontWeight="bold" variant="caption">
                            {`(${position}) ${user}`}
                          </Typography>
                        </ListItem>
                      ))}

                      {shouldShowDivider ? <Divider /> : null}
                    </List>
                  );
                })}
              </Box>
            }
            component={
              <Box display="flex" gap={0.5}>
                <Box>{value.num}</Box>

                {approvals.map(({ details }) =>
                  details.map(({ id, status }) => (
                    <Box
                      bgcolor={ICON_COLORS[status] || 'primary'}
                      borderRadius={20}
                      height={20}
                      key={id}
                      width={8}
                    />
                  )),
                )}
              </Box>
            }
            header={value.title}
            type="custom"
          />
        ) : null}
      </StyledLink>
    </TableCell>
  );
}
