import { MouseEvent, useRef, useState } from 'react';
import { useController } from 'react-hook-form';

import { Dayjs } from 'dayjs';

import { DateFieldProps } from 'src/components/RHF/DateField/DateField';
import {
  buildDatePickerProps,
  computeNewValue,
  stringify,
} from 'src/components/RHF/DateField/utilities/helperFunctions';
import { usePreference } from 'src/utilities/hooks';

export type ReactHookFormValue = string | string[] | undefined;
type UseDateFieldProps = Omit<
  DateFieldProps,
  'isRange' | 'isRawText' | 'isReadOnly' | 'muiBoxDatePickerWrapperProps' | 'shouldShowHelperText'
> &
  Required<Pick<DateFieldProps, 'isRange' | 'shouldShowHelperText'>>;

export function useDateField({
  description,
  isRange,
  muiDatePickerProps,
  onChangeLogicBuilderField,
  shouldShowHelperText,
  useControllerProps,
}: UseDateFieldProps) {
  const { value: showTooltipsPreferenceValue } = usePreference('job.feldtips', 'Y');
  const shouldShowTooltip = showTooltipsPreferenceValue === 'Y' && !!description;

  const anchorReference = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    field: { name, onChange, value: reactHookFormValue },
    fieldState: { error: reactHookFormError },
  } = useController(useControllerProps);
  const value: ReactHookFormValue = reactHookFormValue;
  const stringifiedValue = stringify(value);

  const { label, slots, ...restOfDatePickerProps } = buildDatePickerProps(
    anchorReference,
    shouldShowHelperText,
    reactHookFormError,
    useControllerProps,
    muiDatePickerProps,
  );

  function handleClose(value?: ReactHookFormValue) {
    if (Array.isArray(value) && value.length < 2) return;

    setIsOpen(false);
  }

  function handleOpen(event: MouseEvent<HTMLDivElement, MouseEvent>) {
    if ('tagName' in event.target || (event.target as HTMLElement).closest('svg')) setIsOpen(true);
  }

  function handleChange(incomingValue: Dayjs | null) {
    const newValue = computeNewValue(value, incomingValue, isRange);

    onChange(newValue);

    if (onChangeLogicBuilderField) onChangeLogicBuilderField(name);

    handleClose(newValue);
  }

  return {
    anchorReference,
    handleChange,
    handleClose,
    handleOpen,
    isOpen,
    label,
    name,
    restOfDatePickerProps,
    shouldShowTooltip,
    slots,
    stringifiedValue,
    value,
  };
}
