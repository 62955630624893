import { ChangeEvent } from 'react';
import { Trans } from 'react-i18next';

import { Box, FormControlLabel, Switch, TablePagination, Typography } from '@mui/material';

import { PaginationActions } from 'src/features/JobsTable/components/PaginationActions';
import { useToolbar } from 'src/features/JobsTable/components/Toolbar/useToolbar';
import { labelDisplayedRows } from 'src/features/JobsTable/components/Toolbar/utilities';
import { Common, WithCheckbox } from 'src/features/JobsTable/JobsTable';
import { PAPER_BORDER_RADIUS } from 'src/utilities/stylesOfJobs';

import { IsDense } from 'src/features/JobsTable/types';

type ToolbarOwnProps = {
  onChangeTableDensity: (e: ChangeEvent<HTMLInputElement>) => void;
} & IsDense;
export type ToolbarSharedProps = Pick<Common, 'setPageHistory' | 'totalRowCount'> &
  Partial<Pick<WithCheckbox, 'setSelectedRows'>>;
type ToolbarProps = ToolbarOwnProps & ToolbarSharedProps;

export function Toolbar({
  isDense,
  onChangeTableDensity,
  setPageHistory,
  setSelectedRows,
  totalRowCount,
}: ToolbarProps) {
  const { handleChangePage, handleChangeRowsPerPage, page, rowsPerPage } = useToolbar({
    setPageHistory,
    setSelectedRows,
    totalRowCount,
  });

  return (
    <Box
      bgcolor="grey.100"
      borderRadius={PAPER_BORDER_RADIUS}
      display="flex"
      justifyContent="space-between"
    >
      <FormControlLabel
        control={<Switch checked={isDense} onChange={onChangeTableDensity} />}
        label={
          <Typography variant="body2">
            <Trans i18nKey="lib.dense_padding">Dense Padding</Trans>
          </Typography>
        }
        sx={{ ml: 3 }}
      />

      <TablePagination
        ActionsComponent={PaginationActions}
        component="div"
        count={totalRowCount}
        labelDisplayedRows={labelDisplayedRows}
        labelRowsPerPage={<Trans i18nKey="file-ser.rows_per_page">Rows per page</Trans>}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
      />
    </Box>
  );
}
