import { ReactElement } from 'react';

import { TableCell, TableCellProps } from '@mui/material';

import { CommonCellProps } from 'src/features/JobsTable/types';

type Common = {
  children?: ReactElement | null;
  muiTableCellProps?: TableCellProps;
};
type JobsTable = Pick<CommonCellProps, 'paddingY'>;
type HistoryTable = { paddingY?: never };
type TableButtonCellProps = Common & (JobsTable | HistoryTable);

export function TableButtonCell({ children, muiTableCellProps, paddingY }: TableButtonCellProps) {
  const { sx: tableCellStyleOverrides, ...restOfMuiTableCellProps } = muiTableCellProps ?? {
    sx: {},
  };

  return (
    <TableCell
      sx={{
        paddingX: 0,
        paddingY: paddingY ?? 0,
        width: 40,
        ...tableCellStyleOverrides,
      }}
      {...restOfMuiTableCellProps}
    >
      {children ? children : null}
    </TableCell>
  );
}
