import { Typography } from '@mui/material';

import { LONG_DATE_FORMAT } from 'src/components/RHF/DateField/utilities/constants';
import { DeadlineDateString } from 'src/features/Workflow/Workflow.service';
import { currentDate } from 'src/utilities/constants';
import { useDateFormat } from 'src/utilities/hooks';

type DeadlineProps = {
  isCurrentStatus: boolean;
  deadline: DeadlineDateString;
};

export function Deadline({ deadline, isCurrentStatus }: DeadlineProps) {
  const { formatDate } = useDateFormat();

  const deadlineDateFormatted = formatDate(LONG_DATE_FORMAT, deadline);

  const deadlineDate = new Date(deadline);
  const deadlineDifference = isCurrentStatus ? computeDeadlineDifference() : 0;
  const deadlineDifferenceColor =
    deadlineDifference !== 0 ? (deadlineDifference > 0 ? 'success.main' : 'error.main') : '';
  const deadlineDifferenceSymbol = deadlineDifference > 0 ? '+' : '';

  function computeDeadlineDifference() {
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;
    const millisecondsInASecond = 1000;
    const millisecondsInADay =
      millisecondsInASecond * secondsInAMinute * minutesInAnHour * hoursInADay;

    return Math.floor((+deadlineDate - +currentDate) / millisecondsInADay);
  }

  return (
    <Typography sx={{ mt: 1 }} variant="caption">
      {deadlineDateFormatted}

      {isCurrentStatus ? (
        <Typography sx={{ color: deadlineDifferenceColor, ml: 0.5 }} variant="caption">
          {`(${deadlineDifferenceSymbol}${deadlineDifference})`}
        </Typography>
      ) : null}
    </Typography>
  );
}
