import { Box, Skeleton } from '@mui/material';

import { Autocomplete } from 'src/components/RHF/Autocomplete';
import { Checkbox } from 'src/components/RHF/Checkbox';
import { DateField } from 'src/components/RHF/DateField';
import { ProgressBar } from 'src/components/RHF/ProgressBar';
import { RichText } from 'src/components/RHF/RichText';
import { TextField } from 'src/components/RHF/TextField';
import { useFieldMapper } from 'src/features/JobForm/components/FieldMapper2/useFieldMapper';
import {
  isBarcode,
  isCheckbox,
  isColor,
  isDateField,
  isMemo,
  isNumber,
  isProgressBar,
  isRichTextField,
  isSelectField,
  isTextField,
  isUploadFiles,
} from 'src/features/JobForm/components/FieldMapper2/utilities/helperFunctions';
import { UploadFiles } from 'src/features/UploadFiles';
import { FieldType } from 'src/pages/Job/Job.service';
import { INPUT_HEIGHT } from 'src/utilities/constants';

export type FieldMapper2Props = {
  alias: string;
  description?: string;
  fieldData?: any;
  onChangeLogicBuilderField?: (changedFieldAlias: string) => void;
  onChangeSteeredField?: (fieldName: string) => void;
  onClickAssociatedCheckbox?: (isChecked: boolean) => void;
  isDisabled: boolean;
  isError?: boolean;
  isRange?: boolean;
  isRequired?: boolean;
  isLabelledText?: boolean;
  isRawText?: boolean;
  isReadOnly?: boolean;
  name?: string;
  param?: Record<string, string>;
  shouldUseIntersectionObserver?: boolean;
  type: FieldType;
  fieldValueIndex?: number;
};

export function FieldMapper2({
  alias,
  description = '',
  fieldData = [],
  fieldValueIndex,
  isDisabled,
  isError = false,
  isLabelledText = false,
  isRange = false,
  isRawText = false,
  isReadOnly = false,
  isRequired = false,
  name = '',
  onChangeLogicBuilderField,
  onChangeSteeredField,
  onClickAssociatedCheckbox,
  param,
  shouldUseIntersectionObserver = true,
  type,
}: FieldMapper2Props) {
  const { componentRef, control, isNewJob, isVisible, requiredRule } = useFieldMapper({
    isRequired,
    shouldUseIntersectionObserver,
  });

  if (!isVisible) {
    return (
      <Skeleton
        height={isMemo(type) ? 132 : INPUT_HEIGHT}
        id={name}
        ref={componentRef}
        variant="rounded"
        width="100%"
      />
    );
  }

  return (
    <Box alignItems="center" display="flex" flexGrow={1} ref={componentRef}>
      {isSelectField(type) ? (
        <Autocomplete
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          isRawText={isRawText}
          isReadOnly={isReadOnly}
          label={name}
          multiple={type === 'pick' || type === 'pickselect'}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          onChangeSteeredField={onChangeSteeredField}
          options={fieldData}
          rules={requiredRule}
          shouldShowHelperText={false}
        />
      ) : isCheckbox(type) ? (
        <Checkbox
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          label={name}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          onClickAssociatedCheckbox={onClickAssociatedCheckbox}
          rules={requiredRule}
          shouldShowHelperText={false}
        />
      ) : isDateField(type) ? (
        <DateField
          description={description}
          isRange={isRange}
          isRawText={isRawText}
          isReadOnly={isReadOnly}
          muiDatePickerProps={{
            disabled: isDisabled,
            label: name,
            slotProps: { textField: { error: isError } },
          }}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          shouldShowHelperText={false}
          useControllerProps={{ control, name: alias, rules: requiredRule }}
        />
      ) : isUploadFiles(type) ? (
        <UploadFiles
          destination={param?.dest as string}
          isDisabled={isDisabled}
          isMultipleFiles={false}
          isNewJob={isNewJob}
          key={name}
          label={name}
          variant="jobForm"
        />
      ) : isProgressBar(type) ? (
        <ProgressBar control={control} name={alias} />
      ) : isTextField(type) ? (
        <TextField
          control={control}
          description={description}
          disabled={isDisabled}
          fieldValueIndex={fieldValueIndex}
          isError={isError}
          isLabelledText={isLabelledText}
          isRawText={isRawText}
          isReadOnly={isReadOnly}
          label={name}
          name={alias}
          onChangeLogicBuilderField={onChangeLogicBuilderField}
          rules={requiredRule}
          shouldShowHelperText={false}
          {...(isBarcode(type) && { type: 'barcode' })}
          {...(isMemo(type) && { type: 'memo' })}
          {...(isColor(type) && { type: 'core_color' })}
          {...(isNumber(type) && { type: 'number' })}
        />
      ) : isRichTextField(type) ? (
        <RichText
          control={control}
          fieldValueIndex={fieldValueIndex}
          name={alias}
          placeholder={name}
        />
      ) : null}
    </Box>
  );
}
