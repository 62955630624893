import { useForm } from 'react-hook-form';
import { Trans } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { ForgotPassword } from '../../components/ForgotPassword';
import { TextField } from '../../components/RHF/TextField';
import fiveFlow from '../../images/5Flow.svg';
import { saveTokens, TokensData } from '../../utilities/auth';
import { LoginParams, useLoginMutation } from './Login.service';

import waveLogoSlogan from '../../images/waveLogoSlogan.png';

export function Login() {
  const { installationLogo } = useTheme();
  const { control, handleSubmit } = useForm<LoginParams>({
    mode: 'onBlur',
  });
  const [login, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();

  async function handleLogin({ password, user }: LoginParams) {
    const { redirectUrl, ...tokens } = await login({ password, user }).unwrap();

    saveTokens(tokens as TokensData);
    navigate(`/jobs-${redirectUrl}`);
  }

  return (
    <Box className="public-background vh-100" display="flex" flexDirection="column">
      <Box className="ml-20 mt-20 p-absolute">
        <img alt="5Flow Logo" className="w-100" src={fiveFlow} />
      </Box>

      <Box alignItems="center" alignSelf="center" display="flex" flexGrow={1}>
        <Box display="flex">
          <Box
            className="b-radius-bl-10 b-radius-tl-10 primary-bg w-200 "
            display="flex"
            flexDirection="column"
          >
            <Box className="m-5">
              <img alt="Wave Logo Slogan" className="w-100" src={waveLogoSlogan} />
            </Box>

            <Box alignSelf="center" className="d-flex flex-grow-1 w-100p">
              {installationLogo && (
                <img
                  alt="Installation Logo"
                  className="contain-image w-100p p-10"
                  src={installationLogo}
                />
              )}
            </Box>
          </Box>

          <Box
            alignItems="center"
            className="b-radius-br-10 b-radius-tr-10 filler-bg py-30 w-300"
            display="flex"
            flexDirection="column"
          >
            <Box
              alignItems="center"
              component="form"
              display="flex"
              flexDirection="column"
              onSubmit={handleSubmit(handleLogin)}
            >
              <TextField
                autoFocus
                control={control}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label="Username"
                name="user"
                size="small"
                type="text"
              />

              <TextField
                control={control}
                InputLabelProps={{
                  shrink: true,
                }}
                label="Password"
                name="password"
                size="small"
                type="password"
              />

              <LoadingButton fullWidth loading={isLoading} type="submit">
                <Trans i18nKey="login_sign_in_button">Sign In</Trans>
              </LoadingButton>
            </Box>

            <ForgotPassword />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
