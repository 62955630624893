import { Chip, TableCell, Typography } from '@mui/material';

import { LONG_DATE_FORMAT } from 'src/components/RHF/DateField/utilities/constants';
import { DeadlineValue } from 'src/features/JobsTable/JobsTable.service';
import { SUB_ROW_BORDER_STYLES } from 'src/features/JobsTable/utilities/styles';
import { useDateFormat } from 'src/utilities/hooks';

import { CommonCellProps } from 'src/features/JobsTable/types';

export type DateCellProps = { deadlineValue?: DeadlineValue; value: string } & CommonCellProps;

export function DateCell({ deadlineValue, isSubRow, paddingY, value }: DateCellProps) {
  const { formatDate } = useDateFormat();

  const formattedDate = formatDate(LONG_DATE_FORMAT, value);

  return (
    <TableCell sx={{ paddingY, ...(isSubRow && SUB_ROW_BORDER_STYLES) }}>
      {deadlineValue ? (
        <Chip
          label={formattedDate}
          {...(deadlineValue === 'complete' && {
            color: 'info',
            sx: { textDecoration: 'line-through' },
          })}
          {...(deadlineValue === 'due' && { color: 'warning' })}
          {...(deadlineValue === 'late' && { color: 'error' })}
        />
      ) : (
        <Typography variant="body2">{formattedDate}</Typography>
      )}
    </TableCell>
  );
}
