import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FileIds } from 'src/features/JobFiles/JobFiles';
import {
  FolderCode,
  useGetActionsQuery,
  useLazyGetDownloadUrlQuery,
} from 'src/features/JobFiles/JobFiles.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch, useAppSelector, useRouteParams } from 'src/utilities/hooks';

type UseActionsButton = {
  onToggleSelectAll: (isSelected?: boolean) => void;
  selectedFileIds: FileIds;
  selectedFolderCode: FolderCode;
};

export function useActionsButton({
  onToggleSelectAll,
  selectedFileIds,
  selectedFolderCode,
}: UseActionsButton) {
  const { t } = useTranslation();
  const { age, jobId, jobType } = useRouteParams();

  const actionsButton = useRef<HTMLButtonElement | null>(null);
  const dispatch = useAppDispatch();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const userId = useAppSelector((state) => state.user.details.id);

  const { data: actions } = useGetActionsQuery(
    { age, folderCode: selectedFolderCode, jobId, jobType, userId },
    { skip: !selectedFolderCode },
  );

  const [downloadFiles] = useLazyGetDownloadUrlQuery();

  const buttonsOfActions = useMemo(() => {
    return (
      actions?.other_actions.map(({ code, text }) => {
        const isDisabled =
          selectedFileIds.length < 1 &&
          (code === 'download' ||
            code === 'download_with_annotations' ||
            code === 'annotation-reports' ||
            code === 'annotations' ||
            code === 'hire-notes');

        return {
          code,
          disabled: isDisabled,
          icon: (
            <WaveIcon
              code={`job-files-action-bar-actions-${code}`}
              fontSize="small"
              sx={{ color: 'primary.main' }}
            />
          ),
          onClick: () => handleActions(code),
          text,
          tooltipBody: isDisabled ? 'At least one file needs to be selected' : '',
        };
      }) ?? []
    );
  }, [actions, selectedFileIds, userId]);

  function handleActions(code: string) {
    if (
      code === 'download' ||
      code === 'download_with_annotations' ||
      code === 'annotation-reports' ||
      code === 'annotations' ||
      code === 'hire-notes'
    )
      handleDownloadFiles(code);

    if (code === 'send-link-email') setIsEmailDialogOpen(true);

    handleCloseSubMenu();
  }

  function handleCloseSubMenu() {
    setIsSubMenuOpen(false);
  }

  function handleDownloadFiles(code: string) {
    downloadFiles({
      fileIds: selectedFileIds,
      type: code,
      userId,
    })
      .unwrap()
      .then(({ url }) => {
        window.open(`${import.meta.env.VITE_API}${url}`);

        onToggleSelectAll(false);
        dispatch(
          openWaveSnack({
            message: t('download_successful_content', 'Download was successful'),
            type: 'success',
          }),
        );
      });

    handleCloseSubMenu();
  }

  function handleCloseDialog() {
    setIsEmailDialogOpen(false);
  }

  function handleToggleSubMenu() {
    setIsSubMenuOpen((isPreviousSubMenuOpen) => !isPreviousSubMenuOpen);
  }

  return {
    actionsButton,
    buttonsOfActions,
    handleCloseDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isEmailDialogOpen,
    isSubMenuOpen,
  };
}
