import { Fragment } from 'react';
import { Control } from 'react-hook-form';

import { Box, Divider, Typography } from '@mui/material';

import {
  StyledRadioButton,
  StyledRadioGroup,
} from 'src/features/CompareReplaceDialog/components/CompareReplaceAccordionDetails/CompareReplaceAccordionDetails';
import {
  getFieldLabel,
  OFFSET_COLUMN_WIDTH,
} from 'src/features/CompareReplaceDialog/components/CompareReplaceContent/utils';
import { FieldsTransformed, FieldValue } from 'src/pages/Job/Job.service';

type DialogListProps = {
  filteredSearchData: any;
  jobFields: any;
  integrate: any;
  job: any;
  language: string;
  control: Control<any>;
  handleChangeField: (value: 'current' | 'updated', field: string) => void;
};

export function DialogList({
  control,
  filteredSearchData,
  handleChangeField,
  integrate,
  job,
  jobFields,
  language,
}: DialogListProps) {
  return (
    <>
      {Object.keys(filteredSearchData?.integrate)
        .filter((field) => field !== 'id' && jobFields?.[field])
        .map((field) => {
          const translatedNameField = `name_${language}`;
          const integrateValue = integrate[field as keyof typeof integrate];
          const jobValue = job[field as keyof typeof job];
          const isDifferent = integrateValue !== jobValue;

          return (
            <Fragment key={field}>
              <StyledRadioGroup
                control={control}
                defaultValue="updated"
                name={`selectedFields.${field}`}
                onChange={(value) => handleChangeField(value, field)}
              >
                <Box display="flex" key={field}>
                  <Box
                    alignItems="center"
                    display="flex"
                    marginLeft={1.5}
                    width={OFFSET_COLUMN_WIDTH}
                  >
                    <Typography>
                      {jobFields?.[field]?.[translatedNameField as any] ?? ''}
                    </Typography>
                  </Box>

                  <Box alignItems="center" display="flex" flex="1" marginRight={3}>
                    <StyledRadioButton
                      label={
                        getFieldLabel(
                          field,
                          jobFields as FieldsTransformed,
                          {},
                          jobValue as FieldValue,
                          true,
                        ) || jobValue
                      }
                      size="small"
                      value="current"
                    />
                  </Box>

                  <Box
                    alignItems="center"
                    bgcolor="filler.main"
                    display="flex"
                    flex="1"
                    sx={{
                      backgroundColor: isDifferent ? '#FFECB3' : 'inherit',
                    }}
                  >
                    <StyledRadioButton
                      label={
                        getFieldLabel(
                          field,
                          jobFields as FieldsTransformed,
                          {},
                          integrateValue as FieldValue,
                          true,
                        ) || integrateValue
                      }
                      size="small"
                      value="updated"
                    />
                  </Box>
                </Box>
              </StyledRadioGroup>

              <Divider />
            </Fragment>
          );
        })}
    </>
  );
}
