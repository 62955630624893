import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import { useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { Button, Stack, styled, Typography } from '@mui/material';

import { WaveIcon } from '../WaveIcon';

const StyledButton = styled(Button)`
  min-width: 64px;
  padding: 6px 16px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #191919;
  box-shadow: none;
  border-radius: 10px;
  text-transform: capitalize;
  &:hover,
  &:focus,
  &:active {
    background-color: rgb(17, 17, 17);
    color: #fff;
  }
`;

function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: any;
  resetErrorBoundary: (...args: any[]) => void;
}) {
  const navigate = useNavigate();

  return (
    <Stack height="100vh">
      <Stack alignItems="center" margin="auto" spacing={4} sx={{ paddingTop: 12 }}>
        <Typography variant="h4">Unhandled error!</Typography>

        <StyledButton
          color="error"
          onClick={() => {
            resetErrorBoundary(error);
            navigate(-1);
          }}
          startIcon={<WaveIcon code="not-found-go-back" />}
        >
          Go Back & Try Again
        </StyledButton>
      </Stack>
    </Stack>
  );
}

export function ErrorBoundary({ children }: { children: React.ReactNode }) {
  return (
    <ReactErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error, info) => {
        console.error('Unhandled error', error, info);
        Sentry.captureException(error);
      }}
    >
      {children}
    </ReactErrorBoundary>
  );
}
