import { Theme } from '@mui/material/styles';

import { WaveIcon } from 'src/features/WaveIcon';

export function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:first-of-type': {
            marginTop: 0,
          },
          '&:last-of-type': {
            marginBottom: 0,
          },
          '::before': {
            position: 'unset',
          },
          margin: theme.spacing(2, 0),
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(2),
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: (
          <WaveIcon code="expand-more" sx={{ color: theme.palette.primary.contrastText }} />
        ),
      },
      styleOverrides: {
        root: {
          '&.Mui-expanded': {
            minHeight: 32,
          },
          backgroundColor: theme.palette.primary.main,
          borderRadius: '4px 4px 0 0',
          color: theme.palette.primary.contrastText,
          fontWeight: theme.typography.fontWeightBold,
          height: 32,
          minHeight: 32,
        },
      },
    },
  };
}
