import { ChangeEvent } from 'react';

import { FormControlLabel, Switch as MuiSwitch } from '@mui/material';

type SwitchProps = {
  disabled: boolean | undefined;
  id: string;
  label: string;
  onChange: (event: { target: { checked: boolean; id: string } }) => void;
  value: boolean | undefined;
};

export function Switch({ disabled, id, label, onChange, value }: SwitchProps) {
  function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
    const data = { target: { checked: event?.target?.checked, id } };

    onChange(data);
  }

  return (
    <FormControlLabel
      control={<MuiSwitch checked={value} disabled={disabled} id={id} onChange={handleOnChange} />}
      label={label}
    />
  );
}
