import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

type ReportUrl = {
  authentication: Record<string, string>;
  site: string;
  trusted: string;
  view: Record<string, string>;
};

type GetReportRequest = {
  id?: number;
  reportId: number | string | null;
};

export const reportApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getReports: build.query<ReportUrl, GetReportRequest>({
      query({ id, reportId }) {
        return {
          method: 'GET',
          params: { id },
          url: `/reports/${reportId}/url`,
        };
      },
    }),
  }),
  reducerPath: 'reportApi',
});

export const { useGetReportsQuery } = reportApi;
