import {
  ClipboardData,
  ClipboardField,
  ClipboardSection,
} from 'src/features/JobForm/components/CopyCompareButton/type';
import { FieldsTransformed, FieldValues, JobValues } from 'src/pages/Job/Job.service';
import { Block, FieldLayoutFields, Section } from 'src/pages/Job/JobContext';
import { isFieldValues } from 'src/pages/Job/utilities/helperFunctions';

export function getAddOrUpdateSection(
  sections: ClipboardData,
  newSection: ClipboardSection,
): ClipboardData {
  const sectionIndex = sections.findIndex((section) => section.sectionId === newSection.sectionId);

  if (sectionIndex !== -1) {
    const updatedSections = [...sections];

    updatedSections[sectionIndex] = newSection;

    return updatedSections;
  } else {
    return [...sections, newSection];
  }
}

function getCopiedBlockFields(
  blocks: Array<Block> | undefined,
  jobData: FieldValues,
): Array<ClipboardField> {
  let copiedFields: Array<ClipboardField> = [];

  blocks?.forEach((block) => {
    const blockFields =
      block.fields?.map(({ alias }) => ({
        fieldId: alias,
        value: jobData[alias],
      })) || [];

    copiedFields = copiedFields.concat(blockFields);
  });

  return copiedFields;
}

function getCopiedBlockTableFields(
  blocks: Array<Block> | undefined,
  jobData: FieldValues,
): Array<ClipboardField> {
  let copiedFields: Array<ClipboardField> = [];

  blocks?.forEach((block) => {
    block.table?.rows?.forEach(({ fixedFields, scrollableFields }) => {
      const allFields = fixedFields.concat(scrollableFields);
      const blockFields = allFields.map((alias) => ({
        fieldId: alias,
        value: jobData[alias],
      }));

      copiedFields = copiedFields.concat(blockFields);
    });
  });

  return copiedFields;
}

function getCopiedSectionFields(
  fields: FieldLayoutFields | undefined,
  jobData: FieldValues,
  fieldsTransformed?: FieldsTransformed,
): Array<ClipboardField> {
  return (fields || [])
    .filter((field) => !fieldsTransformed?.[field.alias].isHidden)
    .map((field) => ({
      fieldId: field.alias,
      value: jobData[field.alias],
    }));
}

export function getCopiedSection(
  section: Section,
  jobData: JobValues,
  fieldsTransformed?: FieldsTransformed,
): ClipboardData {
  const { blocks, fields } = section;

  if (!isFieldValues(jobData)) return [];

  const copiedFields = getCopiedSectionFields(fields, jobData, fieldsTransformed);
  const copiedBlockFields = getCopiedBlockFields(blocks, jobData);
  const copiedBlockTableFields = getCopiedBlockTableFields(blocks, jobData);

  return [
    {
      fields: [...copiedFields, ...copiedBlockFields, ...copiedBlockTableFields],
      sectionId: section.code,
    },
  ];
}

export function getUpdatedJobValue(
  dirtyFields: Record<string, string>,
  getValues: (arg: string) => void,
  values?: JobValues,
): JobValues {
  const updatedFields: Record<string, string> = {};

  Object.keys(dirtyFields).forEach((alias) => {
    let fieldValue: any = getValues(alias);

    if (fieldValue?.value) {
      fieldValue = fieldValue.value;
    }

    updatedFields[alias] = fieldValue;
  });

  return { ...values, ...updatedFields };
}
