import { createApi } from '@reduxjs/toolkit/query/react';

import { JobId, Option } from 'src/pages/Job/Job.service';
import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { Age, JobType, Tab } from 'src/utilities/hooks/useRouteParams';

type EmailTemplate = BasicEmailTemplate | (BasicEmailTemplate & WithDate);
type BasicEmailTemplate = { template: { message: string; subject: string }; to_roles: Recipient[] };
type WithDate = { expiration_date: string; expiration_label: string; expiration_tooltip: string };
export type Recipient = {
  checked: boolean;
  id: number;
  name: string;
  type: string;
  role: string;
};
type EmailTemplateTransformed = {
  expirationDate: string;
  expirationLabel: string;
  expirationTooltip: string;
  recipients: Recipient[];
} & BasicEmailTemplate['template'];
type GetEmailTemplateRequest = RouteParameters;
type GetPotentialRecipientsRequest = {
  searchTerm: string;
};
type SendEmailFromCartRequest = {
  senderId?: number;
} & CommonEmailParameters;
type SendEmailFromJobRequest = {
  idsOfAdditionalRecipients: IdsOfRecipients;
  typesOfRecipients: TypesOfRecipients;
} & RouteParameters &
  CommonEmailParameters;
type CommonEmailParameters = {
  idsOfRecipients: IdsOfRecipients;
  message: string;
  subject: string;
};
export type IdsOfRecipients = number[];
export type TypesOfRecipients = string[];
type RouteParameters = Age & JobId & JobType & Tab;

export const emailApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getEmailTemplate: build.query<EmailTemplateTransformed, GetEmailTemplateRequest>({
      query({ age, jobId, jobType, tab }) {
        return {
          method: 'GET',
          params: { age, jobid: jobId, src: jobType, tab },
          url: 'email-dialog',
        };
      },
      transformResponse({
        template: { message, subject },
        to_roles: recipients,
        ...restOfEmailTemplate
      }: EmailTemplate) {
        const expirationDate =
          'expiration_date' in restOfEmailTemplate ? restOfEmailTemplate.expiration_date : '';
        const expirationLabel =
          'expiration_label' in restOfEmailTemplate ? restOfEmailTemplate.expiration_label : '';
        const expirationTooltip =
          'expiration_tooltip' in restOfEmailTemplate ? restOfEmailTemplate.expiration_tooltip : '';

        return {
          expirationDate,
          expirationLabel,
          expirationTooltip,
          message,
          recipients,
          subject,
        };
      },
    }),
    getPotentialRecipients: build.query<Option[], GetPotentialRecipientsRequest>({
      query({ searchTerm }) {
        return {
          method: 'GET',
          params: { term: searchTerm },
          url: 'email-users',
        };
      },
    }),
    sendEmailFromCart: build.mutation<void, SendEmailFromCartRequest>({
      query({ idsOfRecipients, message, senderId, subject }) {
        return {
          method: 'POST',
          params: { message, receiver_id: idsOfRecipients, sender_id: senderId, subject },
          url: 'sendmail',
        };
      },
    }),
    sendEmailFromJob: build.mutation<void, SendEmailFromJobRequest>({
      query({
        age,
        idsOfAdditionalRecipients,
        idsOfRecipients,
        jobId,
        jobType,
        message,
        subject,
        tab,
        typesOfRecipients,
      }) {
        return {
          method: 'POST',
          params: {
            additional_user_ids: idsOfAdditionalRecipients,
            age,
            mail_type: typesOfRecipients,
            message,
            src: jobType,
            subject,
            tab,
            user_ids: idsOfRecipients,
          },
          url: `jobs/${jobId}/emails`,
        };
      },
    }),
  }),
  reducerPath: 'emailApi',
});

export const {
  useGetEmailTemplateQuery,
  useLazyGetPotentialRecipientsQuery,
  useSendEmailFromCartMutation,
  useSendEmailFromJobMutation,
} = emailApi;
