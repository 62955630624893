import { useEffect } from 'react';
import { Control, FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { Checkbox } from 'src/components/RHF/Checkbox';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { determineRevisorId } from 'src/features/JobForm/helperFunctions';
import {
  ExternalUserRevisor,
  GroupRevisor,
  UserRevisor,
} from 'src/features/JobForm/JobForm.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { sortArrayOfObjectsByKey } from 'src/utilities/helperFunctions2';

type RevisorProps = {
  control: Control<FieldValues, any>;
  revisors: (ExternalUserRevisor | GroupRevisor | UserRevisor)[];
  setValue: UseFormSetValue<FieldValues>;
  variant: 'startTaskRevisor' | 'approvalRevisor';
};

export function Revisor({ control, revisors, setValue, variant }: RevisorProps) {
  const columns = [
    { code: 'Position', text: 'Position' },
    { code: 'Responsible Party', text: 'Reponsible Party' },
    { code: 'Days', text: 'Days' },
  ];

  const watchRevisorCheckBox = useWatch({
    control,
    name: Object.keys(useWatch({ control })).filter((key) => key.includes('Revisor')),
  });

  const isIndeterminate =
    watchRevisorCheckBox.includes(false) &&
    watchRevisorCheckBox.filter((check) => !check).length !== watchRevisorCheckBox.length;

  useEffect(() => {
    if (watchRevisorCheckBox.every((check) => check)) setValue('selectAll', true);
    else if (watchRevisorCheckBox.every((check) => !check)) setValue('selectAll', false);
  }, [watchRevisorCheckBox]);

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <Checkbox
                control={control}
                indeterminate={isIndeterminate}
                name="selectAll"
                shouldShowHelperText={false}
              />
            </TableCell>

            {columns.map((column) => (
              <Box component={TableCell} fontWeight="bold" key={column.code}>
                {column.text}
              </Box>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {revisors.map((revisor) => {
            const { duration, position, type } = revisor;
            const isExternal = type === 'email_ext';
            const isGroup = type === 'email_gru';
            const isGroupRole = type === 'email_gruasrole';
            const isUser = type === 'email_usr';
            const isUserRole = type === 'email_rol';
            const revisorId = determineRevisorId(revisor);

            return (
              <TableRow key={revisorId}>
                <TableCell width={65}>
                  <Checkbox
                    control={control}
                    name={`${variant}${revisorId}`}
                    shouldShowHelperText={false}
                  />
                </TableCell>

                <TableCell width={80}>
                  <Typography variant="subtitle2">{position}</Typography>
                </TableCell>

                <TableCell>
                  {isGroup || isGroupRole ? (
                    <Box sx={{ alignItems: 'center', display: 'flex', gap: 2 }}>
                      <Typography variant="subtitle2">
                        {isGroup ? revisor.group : null}

                        {isGroupRole ? `${revisor.role}: ${revisor.group}` : null}
                      </Typography>

                      <WaveTooltip
                        body={sortArrayOfObjectsByKey(
                          Object.entries(revisor.users).map((user) => ({
                            name: user[1],
                            userId: parseInt(user[0]),
                          })),
                          'name',
                        )}
                        component={<WaveIcon code="job-form-steps-dialog-revisor-group" />}
                        header={`${revisor.group} Users`}
                        placement="right"
                        type="list"
                      />
                    </Box>
                  ) : (
                    <Typography variant="subtitle2">
                      {isExternal ? revisor.email : null}

                      {isUserRole ? `${revisor.role}: ${revisor.username}` : null}

                      {isUser ? revisor.username : null}
                    </Typography>
                  )}
                </TableCell>

                <TableCell>
                  <Typography variant="subtitle2">{duration}</Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
