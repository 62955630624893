import { useEffect, useState } from 'react';

import { Outlet, useNavigate } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import { Box } from '@mui/material';

import { Frame } from '../../features/Frame';
import { getUserDetails } from '../../store/userSlice';
import { setupRequestInterceptor, setupResponseInterceptor } from '../../utilities/api';
import { logout, requestInterceptor } from '../../utilities/auth';
import { useAppDispatch } from '../../utilities/hooks';
import { FullScreenProvider } from '../../utilities/hooks/FullScreenContext';

export function SecureZone() {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  async function authenticate() {
    setupResponseInterceptor({
      onNotFound: () => navigate('404', { replace: true }),
      onUnauthorized: () => logout(),
    });
    setupRequestInterceptor(requestInterceptor);

    return dispatch(getUserDetails());
  }

  useEffect(() => {
    authenticate()
      .then((userInfo) => {
        Sentry.setUser({ username: userInfo.payload.details.user });
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        logout();
      });
  }, []);

  if (isLoading) return <div />;

  return (
    <FullScreenProvider>
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Frame>
          <Box
            component="main"
            sx={{ display: 'flex', flexDirection: 'column', overflow: 'auto', width: '100%' }}
          >
            <Outlet />
          </Box>
        </Frame>
      </Box>
    </FullScreenProvider>
  );
}
