import { Trans, useTranslation } from 'react-i18next';

import { LoadingButton } from '@mui/lab';
import { Skeleton } from '@mui/material';

import { WaveTooltip } from 'src/components/WaveTooltip';
import { IntegrateUpdateDialog } from 'src/features/IntegrateUpdateDialog';
import { useActions } from 'src/features/JobForm/components/Actions/useActions';
import { SendEmailDialog } from 'src/features/SendEmailDialog';
import { SubMenu } from 'src/features/SubMenu';
import { WaveIcon } from 'src/features/WaveIcon';

export function Actions() {
  const { t } = useTranslation();
  const {
    actionButtons,
    actionsButton,
    handleCloseDialog,
    handleCloseIntegrateUpdateDialog,
    handleCloseSubMenu,
    handleToggleSubMenu,
    isDirty,
    isEmailDialogOpen,
    isFetching,
    isIntegrateUpdateDialogOpen,
    isPerformingAction,
    isSubMenuOpen,
  } = useActions();

  return isFetching ? (
    <Skeleton height={30} variant="rounded" width={80} />
  ) : actionButtons.length ? (
    <>
      <WaveTooltip
        component={
          <LoadingButton
            color="secondary"
            disabled={isDirty}
            loading={isPerformingAction}
            onClick={handleToggleSubMenu}
            ref={actionsButton}
            startIcon={<WaveIcon code="job-form-action-bar-actions" />}
            {...(isSubMenuOpen && { sx: { bgcolor: 'secondary.dark' } })}
          >
            <Trans i18nKey="lib.actions">Actions</Trans>
          </LoadingButton>
        }
        placement="top"
        type="simple"
        {...(isDirty && {
          body: t(
            'form_save_other_actions',
            'Save the job to apply your changes or refresh the page to undo them. Only then, can you perform other job actions.',
          ) as string,
        })}
        {...(isDirty && { header: t('form_changes_made', 'Changes Have Been Made') as string })}
      />

      <SubMenu
        anchorEl={actionsButton.current}
        anchorElMinWidth={actionsButton.current?.offsetWidth}
        anchorOriginVerticalSubitem="top"
        fontSize="caption"
        items={actionButtons}
        onClose={handleCloseSubMenu}
        open={isSubMenuOpen}
        transformOriginVerticalSubitem="top"
      />

      <SendEmailDialog
        isOpen={isEmailDialogOpen}
        onClose={handleCloseDialog}
        title="lib.email.new"
      />

      <IntegrateUpdateDialog
        isOpen={isIntegrateUpdateDialogOpen}
        onClose={handleCloseIntegrateUpdateDialog}
      />
    </>
  ) : null;
}
