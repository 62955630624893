import { Paper, Typography, useTheme } from '@mui/material';
import { ChartsItemContentProps } from '@mui/x-charts';

type CustomProps = {
  items: {
    label: string;
    total?: number;
    color?: string;
    id?: string;
    value?: number;
  }[];
};

type Props = CustomProps & ChartsItemContentProps<any>;

export function ChartTooltip(props: Props) {
  const theme = useTheme();

  const { itemData, items } = props;

  const itemIndex = itemData.dataIndex as number;
  const item = items[itemIndex];

  return (
    <Paper
      elevation={3}
      sx={{
        '&::after': {
          border: '7px solid transparent',
          borderLeft: '0',
          borderRightColor: 'rgba(0, 0, 0, 0.7)',
          content: '""',
          height: '0',
          left: '0',
          marginLeft: '-6px',
          marginTop: '-7px',
          position: 'absolute',
          top: '50%',
          width: '0',
        },
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '10px',
        color: theme.palette.common.white,
        marginBottom: '20px',
        padding: '5px 10px !important',
        position: 'relative',
        right: '-15px',
        top: '-40px',
      }}
    >
      <Typography component="div" sx={{ fontWeight: 700 }} variant="subtitle1">
        {item.label}
      </Typography>

      <Typography alignItems="center" display="flex">
        <Typography
          component="div"
          sx={{
            background: item.color || theme.palette.common.black,
            border: `2px solid ${theme.palette.common.white}`,
            height: '12px',
            marginRight: '4px',
            width: '12px',
          }}
        ></Typography>

        <Typography component="span" sx={{ fontSize: '13px' }} variant="body1">
          {item.total && `Total Task: ${item.total}`}

          {item.value && `${item.value}`}
        </Typography>
      </Typography>
    </Paper>
  );
}
