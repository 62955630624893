import { Palette, PaletteColor, Theme } from '@mui/material';
import { PaletteAugmentColorOptions } from '@mui/material/styles/createPalette';

import { Accordion } from 'src/components/WaveTheme/overrides/Accordion';
import { Button } from 'src/components/WaveTheme/overrides/Button';
import { Dialog } from 'src/components/WaveTheme/overrides/Dialog';
import { Paper } from 'src/components/WaveTheme/overrides/Paper';
import { Tabs } from 'src/components/WaveTheme/overrides/Tabs';
import { TextField } from 'src/components/WaveTheme/overrides/TextField';

type AugmentColorFunction = (options: PaletteAugmentColorOptions) => PaletteColor;

type PaletteColorKeys = {
  [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never;
}[keyof Palette];

export function getPaletteColorForJobType(theme: Theme, jobType?: string): PaletteColor {
  const defaultColorKey: PaletteColorKeys = 'primary';
  const colorKey: PaletteColorKeys = (
    jobType && theme.palette[jobType as keyof typeof theme.palette] ? jobType : defaultColorKey
  ) as PaletteColorKeys;

  return theme.palette[colorKey];
}

function lightenColor(hex: string, percent: number): string {
  // Ensure the hex is a valid hex color
  if (!/^#([A-Fa-f0-9]{6})$/.test(hex)) {
    throw new Error('Invalid HEX color.');
  }

  // Convert hex to RGB
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  // Calculate the blend with white
  r += Math.round((255 - r) * (percent / 100));
  g += Math.round((255 - g) * (percent / 100));
  b += Math.round((255 - b) * (percent / 100));

  // Ensure values are within RGB range
  r = Math.min(255, r);
  g = Math.min(255, g);
  b = Math.min(255, b);

  // Convert back to hex and return
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b
    .toString(16)
    .padStart(2, '0')}`;
}

export function extendAugmentColor(
  originalFunction: AugmentColorFunction,
): (
  options: PaletteAugmentColorOptions & { lighterPercent?: number },
) => PaletteColor & { lighter: string } {
  return function ({ color, lighterPercent = 60 }): PaletteColor {
    const augmentedColor = originalFunction({ color });
    const lighterColor = lightenColor(augmentedColor.main, lighterPercent);

    return {
      ...augmentedColor,
      lighter: lighterColor,
    };
  };
}

export function overrideStylesOfComponents(theme: Theme) {
  return Object.assign(Accordion(theme), Button(), Dialog(theme), Paper(), Tabs(), TextField());
}
