import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Button, CircularProgress, IconButton } from '@mui/material';

import { BreadCrumbs } from 'src/components/BreadCrumbs';
import {
  useCompareFilesQuery,
  useGetApprovalsQuery,
  useGetFilesQuery,
} from 'src/features/Approvals/Approvals.service';
import { ActionBar } from 'src/features/Approvals/components/ActionBar';
import { useGetProofscopeFileQuery } from 'src/features/FileExplorer/FileExplorer.service';
import { WaveIcon } from 'src/features/WaveIcon';
import { useLazyNotifyBackendQuery } from 'src/pages/Viewer/ViewerCallback.service';
import {
  useAppSelector,
  useIsExternalRevisor,
  useRouteParams,
  useSelect,
} from 'src/utilities/hooks';
import { useFullScreen } from 'src/utilities/hooks/FullScreenContext';
import { parseArrayToLogicalObject } from 'src/utilities/parsers';

export function Viewer() {
  const { age, jobId, jobType } = useRouteParams();

  const { handleChangeFullScreen, isFullScreen, setIsFullScreen } = useFullScreen();
  const [isFrameLoading, setIsFrameLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const userId = useAppSelector((state) => state.user.details.id);

  const queryParams = new URLSearchParams(location.search);
  const fileType = queryParams.get('type');
  const fileId = queryParams.get('fileId');
  const sourceAge = queryParams.get('age');
  const tab = queryParams.get('tab');
  const compareFile = queryParams.get('compareFile');
  const pathname = location.pathname;
  const token = pathname.split('/')[2];

  const isExternalRevisorView = useIsExternalRevisor();
  const [notifyBackend] = useLazyNotifyBackendQuery();

  const { data: { other_details: otherDetails, url } = {} } = useGetProofscopeFileQuery(
    {
      age,
      fileId: Number(fileId),
      jobId,
      jobType,
    },
    { skip: compareFile === 'true' },
  );

  const { data: compareUrl } = useCompareFilesQuery(
    {
      age,
      file_id: fileId?.split(',').map(Number),
      jobid: jobId,
      src: jobType,
    },
    { skip: compareFile === 'false' },
  );

  const {
    data: approvals = [],
    isLoading,
    isSuccess: areApprovalsLoadedSuccessfully,
  } = useGetApprovalsQuery({
    age,
    jobid: jobId,
    src: jobType,
  });

  const { data: files = { categorizedFiles: {}, rawFiles: [] } } = useGetFilesQuery({
    age,
    jobId,
    src: jobType,
    type: fileType || 'cloudflow',
  });

  useEffect(() => {
    if (fileType === 'dalim' && otherDetails) {
      const existingScript = document.querySelector(`script[src="${otherDetails.js_path}"]`);

      if (existingScript) {
        console.log(`Script ${otherDetails.js_path} already exists in the document.`);

        // reusable part goes here
        return;
      }

      const script = document.createElement('script');

      script.src = otherDetails.js_path;
      script.async = true;

      script.onload = () => {
        console.log(`Script ${otherDetails.js_path} loaded successfully`);
        setIsFrameLoading(false);

        function resolver(resourceName: string) {
          console.log('resolver - ', `${otherDetails?.url}html/${resourceName}`);

          return `${otherDetails?.url}html/${resourceName}`;
        }

        const pageModel = otherDetails?.docs.map((doc) => ({
          displayName: doc.displayName,
          id: doc.id,
        }));

        const startMeUp = {
          container: 'dalim',
          disableApprovalComments: 0,
          lang: otherDetails.language,
          // ?
          pageModel: [pageModel],
          // ?
          resourceResolver: resolver,
          // "unit.resolution": 300,
          // ?
          reversed: 0,

          // ?
          rootURL: `${otherDetails.url.slice(0, -1)}`,

          sessionID: otherDetails.session,

          'unit.length': 'mm',

          userName: otherDetails.username,
        };

        console.log(startMeUp);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.dialog.sdk.initFrom(startMeUp);
      };

      script.onerror = () => {
        console.error(`Failed to load script ${otherDetails.js_path}`);
      };

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [otherDetails, fileType]);

  const { selectedItemIds } = useSelect(parseArrayToLogicalObject(files.rawFiles, 'id'));

  function handleCloseProofscopePage() {
    if (isFullScreen) handleChangeFullScreen();

    const url = isExternalRevisorView
      ? `/external-revisor/${token}/jobs-job-${jobType}/${jobId}`
      : sourceAge
      ? `/jobs-hom-${sourceAge}`
      : tab === 'compare'
      ? `/jobs-job-${jobType}/${jobId}/fil`
      : `/jobs-job-${jobType}${tab ? `/${jobId}/${tab}` : ''}`;

    notifyBackend({
      age,
      id: userId,
      jobid: jobId,
      src: jobType,
      ...(fileType === 'dalim' || fileType === 'cloudflow' ? { sub: fileType, url } : {}),
    }).then(() => {
      navigate(url, { replace: true });
    });
  }

  useEffect(() => {
    if (!isFullScreen) {
      setIsFullScreen(true);
    }
  }, []);

  // const iframeUrl = fileType === 'dalim' ? other_details.base_url : url;

  // console.log('url -', url, 'fileType - ', fileType);

  return (
    <>
      {!isExternalRevisorView ? <BreadCrumbs /> : null}

      <Box alignItems="center" display="flex" justifyContent="space-between" pt={2} px={1}>
        <Box pb={2}>
          {!isExternalRevisorView ? (
            <Button
              onClick={handleChangeFullScreen}
              startIcon={<WaveIcon code={isFullScreen ? 'exit-fullscreen' : 'fullscreen'} />}
              variant="outlined"
            >
              {isFullScreen ? 'Exit Fullscreen' : 'Show Fullscreen'}
            </Button>
          ) : null}
        </Box>

        <Box alignItems="center" display="flex" gap={1}>
          {areApprovalsLoadedSuccessfully && !approvals.length && !isLoading ? null : (
            <ActionBar selectedFileIds={selectedItemIds as number[]} />
          )}

          <Box pb={2}>
            <IconButton onClick={handleCloseProofscopePage}>
              <WaveIcon code="close" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        height="calc(100vh - 72px)"
        justifyContent="center"
        position="relative"
      >
        {isFrameLoading && (
          <Box position="absolute">
            <CircularProgress className="spinner-progress" size={100} />
          </Box>
        )}

        <div id="dalim" />

        {fileType !== 'dalim' && (
          <iframe
            className="border-none"
            height="100%"
            onLoad={() => setIsFrameLoading(false)}
            src={compareFile === 'true' ? compareUrl : url}
            width="100%"
          />
        )}
      </Box>
    </>
  );
}
