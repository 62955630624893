export const PAPER_BORDER_RADIUS = 2;
export const PAGE_PADDING = 2;

export const CONTAINER_STYLES = { mb: 1, mt: 1.5, px: PAGE_PADDING } as const;
export const STACK_STYLES = {
  direction: 'row',
  flexGrow: 1,
  overflow: 'hidden',
  p: PAGE_PADDING,
  pb: 0,
  pt: 1,
} as const;
