import { Trans } from 'react-i18next';

import dayjs from 'dayjs';

import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Popper,
  PopperProps,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { Button } from 'src/components/Button';
import { WaveDialogTitle } from 'src/components/WaveDialogTitle';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { Recipient } from 'src/features/SendEmailDialog/components/Recipient';
import { useSendEmailDialog } from 'src/features/SendEmailDialog/useSendEmailDialog';
import { COMMON_INPUT_PROPS } from 'src/features/SendEmailDialog/utilities/constants';
import { INPUT_HEIGHT } from 'src/utilities/constants';

export type SendEmailDialogProps = {
  isOpen: boolean;
  isShoppingCart?: boolean;
  onClose: () => void;
  title: string;
};

export function SendEmailDialog({
  isOpen,
  isShoppingCart = false,
  onClose,
  title,
}: SendEmailDialogProps) {
  const {
    additionalRecipients,
    emailTemplate,
    handleChangeAdditionalRecipients,
    handleChangeAdditionalRecipientsSearchTerm,
    handleChangeMessage,
    handleChangeSubject,
    handleClickRecipientCheckbox,
    handleSendEmail,
    isDisabled,
    isFetchingPotentialRecipients,
    linkExpirationDate,
    message,
    potentialRecipients,
    recipients,
    setLinkExpirationDate,
    subject,
    translate,
  } = useSendEmailDialog({
    isOpen,
    isShoppingCart,
    onClose,
  });

  return (
    <Dialog fullWidth maxWidth="md" onClose={onClose} open={isOpen}>
      <WaveDialogTitle onClose={onClose} title={<Trans>{title}</Trans>} />

      <DialogContent>
        <Stack spacing={2}>
          {isShoppingCart && linkExpirationDate?.isValid() ? (
            <Box width={168}>
              {!emailTemplate ? (
                <Skeleton height={INPUT_HEIGHT} variant="rounded" />
              ) : (
                <WaveTooltip
                  body={emailTemplate.expirationTooltip}
                  component={
                    <DatePicker
                      label={emailTemplate.expirationLabel}
                      maxDate={linkExpirationDate}
                      minDate={dayjs()}
                      onChange={setLinkExpirationDate}
                      slotProps={{ textField: COMMON_INPUT_PROPS }}
                      value={linkExpirationDate}
                    />
                  }
                  placement="right"
                  type="simple"
                />
              )}
            </Box>
          ) : null}

          {recipients.length ? (
            <Stack alignItems="center" direction="row" gap={2}>
              <Typography>{`${translate('to', 'To')}: `}</Typography>

              <Stack>
                {recipients.map((recipient) => (
                  <Recipient
                    key={recipient.id}
                    onClickRecipientCheckbox={handleClickRecipientCheckbox}
                    recipient={recipient}
                  />
                ))}
              </Stack>
            </Stack>
          ) : null}

          {!emailTemplate ? (
            <Skeleton height={INPUT_HEIGHT} variant="rounded" />
          ) : (
            <Autocomplete
              forcePopupIcon={false}
              getOptionLabel={({ label }) => label}
              isOptionEqualToValue={(option, value) => option.label === value.label}
              loading={isFetchingPotentialRecipients}
              multiple
              onChange={handleChangeAdditionalRecipients}
              onInputChange={handleChangeAdditionalRecipientsSearchTerm}
              options={potentialRecipients}
              PopperComponent={({
                disablePortal: _disablePortal,
                ...restOfPopperProps
              }: PopperProps) =>
                potentialRecipients.length ? <Popper {...restOfPopperProps} disablePortal /> : null
              }
              renderInput={({
                InputProps: { endAdornment, ...restOfInputProps },
                ...restOfTextFieldProps
              }) => (
                <TextField
                  InputProps={{
                    endAdornment: isFetchingPotentialRecipients ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      endAdornment
                    ),
                    ...restOfInputProps,
                  }}
                  label={
                    !recipients.length
                      ? translate('to', 'To')
                      : translate('additional_recipient', 'Additional Recipients')
                  }
                  {...restOfTextFieldProps}
                />
              )}
              value={additionalRecipients}
              {...COMMON_INPUT_PROPS}
            />
          )}

          {!emailTemplate ? (
            <Skeleton height={INPUT_HEIGHT} variant="rounded" />
          ) : (
            <TextField
              label={<Trans>lib.sbj</Trans>}
              onChange={handleChangeSubject}
              value={subject}
              {...COMMON_INPUT_PROPS}
            />
          )}

          {!emailTemplate ? (
            <Skeleton height={270} variant="rounded" />
          ) : (
            <TextField
              label={<Trans>sys.message</Trans>}
              multiline
              onChange={handleChangeMessage}
              rows={11}
              value={message}
              {...COMMON_INPUT_PROPS}
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'space-between' }}>
        <Button color="warning" onClick={onClose}>
          lib.cancel
        </Button>

        <WaveTooltip
          body={isDisabled ? `${translate('recipient_required', 'Recipient is required')}` : ''}
          component={
            <Button disabled={isDisabled} onClick={handleSendEmail}>
              lib.send
            </Button>
          }
          placement="right"
          type="simple"
        />
      </DialogActions>
    </Dialog>
  );
}
