import { createApi } from '@reduxjs/toolkit/dist/query/react';

import { Fields, FieldsTransformed } from 'src/pages/Job/Job.service';
import { transformFields } from 'src/pages/Job/utilities/helperFunctions';
import { axiosBaseQuery } from 'src/utilities/baseQuery';

type GetActionBarAndFieldsRequest = { jobType?: string };
type GetActionBarAndFieldsResponse = { actions: ActionBar; fields: Fields };
type GetActionBarAndFieldsResponseTransformed = {
  actionBar: ActionBar;
  fields: FieldsTransformed;
};
type ActionBar = {
  buttons: ActionBarButton[];
  steps: { code: string; name: string }[];
};
export type ActionBarButton = { code: ActionBarCode; name: string };
export type ActionBarCode = 'cancel' | 'next' | 'back' | 'confirm';

export const bulkEditJobFieldsApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getActionBarAndFields: build.query<
      GetActionBarAndFieldsResponseTransformed,
      GetActionBarAndFieldsRequest
    >({
      providesTags: ['ActionBarAndFields'],
      query({ jobType }) {
        return {
          method: 'GET',
          params: { src: jobType },
          url: '/jobs/bulk/fields',
        };
      },
      transformResponse({ actions, fields }: GetActionBarAndFieldsResponse) {
        return { actionBar: actions, fields: transformFields(fields) };
      },
    }),
  }),
  reducerPath: 'bulkEditJobFieldsApi',
  tagTypes: ['ActionBarAndFields'],
});

export const { useGetActionBarAndFieldsQuery } = bulkEditJobFieldsApi;
