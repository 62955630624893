import { useCallback, useState } from 'react';

import { Badge, Drawer, IconButton, Stack, Typography } from '@mui/material';

import { WaveIcon } from '../../../WaveIcon';

export function Notifications() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onOpenDrawer = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onCloseDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  const renderHead = (
    <Stack alignItems="center" direction="row" sx={{ minHeight: 68, pl: 2.5, pr: 1, py: 2 }}>
      <Typography sx={{ flexGrow: 1 }} variant="h6">
        Notifications
      </Typography>
    </Stack>
  );

  return (
    <>
      <IconButton color="secondary" onClick={onOpenDrawer} size="large">
        <Badge badgeContent={0} color="error">
          <WaveIcon code="notifications" />
        </Badge>
      </IconButton>

      <Drawer
        anchor="right"
        onClose={onCloseDrawer}
        open={isOpen}
        PaperProps={{ sx: { maxWidth: 420, width: 1 } }}
        slotProps={{ backdrop: { invisible: true } }}
      >
        {renderHead}
      </Drawer>
    </>
  );
}
