import { useEffect } from 'react';

import { isEqual } from 'lodash';

import { PropsOfActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs/ActiveFiltersOfJobs';
import { usePreference, usePreferencePrefix } from 'src/utilities/hooks';
import { ActiveFilter } from 'src/utilities/hooks/usePreference';

type PropsOfUseActiveFiltersOfJobs = Pick<PropsOfActiveFiltersOfJobs, 'setAreActiveFiltersVisible'>;

export function useActiveFiltersOfJobs(props?: PropsOfUseActiveFiltersOfJobs) {
  const { jobType, preferencePrefix } = usePreferencePrefix();
  const activeFiltersPreference = usePreference(`${preferencePrefix}.search`, []);
  const areActiveFiltersLoading = activeFiltersPreference.isLoading;
  const setActiveFilters = activeFiltersPreference.set;
  const activeFilters = activeFiltersPreference.value as ActiveFilter[];

  function handleDeleteActiveFilter(deleteFilter: ActiveFilter) {
    const filteredActiveFilters = activeFilters.filter(
      (activeFilter) => !isEqual(activeFilter, deleteFilter),
    );

    setActiveFilters(filteredActiveFilters);
  }

  function handleDeleteAllActiveFilters() {
    setActiveFilters([]);
  }

  useEffect(() => {
    props?.setAreActiveFiltersVisible(true);
  }, [activeFilters, jobType]);

  return {
    activeFilters,
    areActiveFiltersLoading,
    handleDeleteActiveFilter,
    handleDeleteAllActiveFilters,
    setActiveFilters,
  };
}
