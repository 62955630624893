/* eslint-disable react/destructuring-assignment */

import { Chip } from '@mui/material';

import { BasicFilters, FiltersOfJobs } from 'src/features/ActiveFilters/ActiveFilters';
import {
  buildLabel,
  isJobsFilter,
} from 'src/features/ActiveFilters/components/ActiveFilter/utilities/helperFunctions';
import { ActiveFilter } from 'src/utilities/hooks/usePreference';

import { Filter } from 'src/features/History/History.types';

export type BasicFilter = Omit<BasicFilters, 'activeFilters'> & { filter: Filter };
export type JobsFilter = Omit<FiltersOfJobs, 'activeFilters'> & { filter: ActiveFilter };
export type ActiveFilterProps = BasicFilter | JobsFilter;

export function ActiveFilter(props: ActiveFilterProps) {
  const areActiveFiltersLoading = isJobsFilter(props) ? props.areActiveFiltersLoading : undefined;
  const label = buildLabel(props);

  function handleDelete() {
    if (isJobsFilter(props)) props.onDeleteActiveFilter(props.filter);
    else props.onDeleteActiveFilter(props.filter);
  }

  return <Chip disabled={areActiveFiltersLoading} label={label} onDelete={handleDelete} />;
}
