import { Box, CircularProgress, TableCell, Typography } from '@mui/material';

import { StatusIndicator } from 'src/components/StatusIndicator';
import { useStatusCell } from 'src/features/JobsTable/components/StatusCell/useStatusCell';
import { StyledLink } from 'src/features/JobsTable/components/StyledLink/StyledLink';
import { RowTransformed } from 'src/features/JobsTable/JobsTable.service';
import { SUB_ROW_BORDER_STYLES } from 'src/features/JobsTable/utilities/styles';

import { CommonCellProps, JobLink, RowJobType } from 'src/features/JobsTable/types';

export type StatusCellProps = { value: RowTransformed['status'] } & CommonCellProps &
  JobLink &
  RowJobType;

export function StatusCell({ isSubRow, jobLink, paddingY, rowJobType, value }: StatusCellProps) {
  const { isWorkflowFetching, workflow, workflowStatus } = useStatusCell({ rowJobType, value });

  return (
    <TableCell
      sx={{
        paddingY,
        whiteSpace: 'nowrap',
        ...(isSubRow && SUB_ROW_BORDER_STYLES),
      }}
    >
      <StyledLink href={jobLink} sx={{ position: 'relative' }}>
        {isWorkflowFetching ? (
          <CircularProgress
            size={24}
            sx={{
              left: '50%',
              marginLeft: -1.5,
              marginTop: -1.5,
              position: 'absolute',
              top: '50%',
            }}
          />
        ) : !workflow || !workflowStatus ? (
          <Typography color="error" textAlign="center" variant="body2">
            Unknown Status
          </Typography>
        ) : (
          <>
            {paddingY && workflowStatus.name_en ? (
              <Typography variant="body2">{workflowStatus.name_en}</Typography>
            ) : null}

            <Box display="flex" gap={0.5}>
              {Object.values(workflow).map((status) => {
                const statusValue = status?.value;
                const isActive = statusValue?.toString() === value.toString();

                return (
                  <StatusIndicator
                    hasElevation={false}
                    isActive={isActive}
                    isLighter
                    jobType={rowJobType}
                    key={statusValue}
                    label={status?.displayValue.toString()}
                    size="extraSmall"
                  />
                );
              })}
            </Box>
          </>
        )}
      </StyledLink>
    </TableCell>
  );
}
