import { Box, Drawer } from '@mui/material';

import { Footer } from 'src/features/Frame/components/Footer';
import { MainMenu } from 'src/features/Frame/components/MainMenu';
import { SupportMenu } from 'src/features/Frame/components/SupportMenu';
import { DRAWER_TRANSITION } from 'src/utilities/drawerStyles';
import { withFullScreen } from 'src/utilities/hooks';

type NavSideBarProps = { isOpen: boolean; onToggleDrawer: () => void };

export function NavSideBar({ isOpen, onToggleDrawer }: NavSideBarProps) {
  return (
    <Drawer
      PaperProps={{
        sx: {
          bgcolor: 'primary.main',
          border: 0,
          justifyContent: 'space-between',
          overflowX: 'hidden',
          position: 'static',
        },
      }}
      sx={{
        boxShadow: '4px 0 5px -2px #888',
        position: 'relative',
        width: isOpen ? 240 : 64,
        zIndex: 3,
        ...DRAWER_TRANSITION,
      }}
      variant="permanent"
    >
      <MainMenu isOpen={isOpen} onToggleDrawer={onToggleDrawer} />

      <Box>
        <SupportMenu isOpen={isOpen} />

        {isOpen ? <Footer /> : null}
      </Box>
    </Drawer>
  );
}

export const VisibilityNavSideBar = withFullScreen(NavSideBar, { hideInFullScreen: true });
