import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from 'src/utilities/baseQuery';

export type TeamMembers = { [key: number]: string };

type BackupUsersParams = {
  backupUserId?: number;
  userId?: number;
};

type UpdateUserBackupParams = {
  id?: number;
  type: 'ibackup' | 'mybackup';
  status: 0 | 1;
  url: string;
}

export const dashboardApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getBackupUsers: build.query<any, BackupUsersParams>({
      query({ backupUserId, userId }) {
        return {
          method: 'GET',
          params: {
            backupuserid: backupUserId,
            userid: userId,
          },
          url: '/users/backup',
        };
      },
    }),
    getTeamMembers: build.query<{ data: TeamMembers }, {}>({
      providesTags: ['TeamMembers'],
      query() {
        return {
          method: 'GET',
          url: '/membership/getteammembers',
        };
      },
    }),
    updateUserBackup: build.mutation<{ message: string, data: string }, UpdateUserBackupParams>({
      query({ id, status, type, url }) {
        return { 
          method: 'PUT',
          params: { id, status, type },
          url,
        };
      },
    }),
  }),
  reducerPath: 'dashboardApi',
  tagTypes: ['TeamMembers'],
});

export const {
  useGetBackupUsersQuery,
  useGetTeamMembersQuery,
  useUpdateUserBackupMutation,
} = dashboardApi;