import {
  IdsOfRecipients,
  Recipient,
  TypesOfRecipients,
} from 'src/features/SendEmailDialog/Email.service';
import { Option } from 'src/pages/Job/Job.service';
import { isString } from 'src/utilities/helperFunctions2';
import { Tab } from 'src/utilities/hooks/useRouteParams';

export function computeIsDisabled(
  isShoppingCart: boolean,
  recipients: Recipient[],
  additionalRecipients: Option[],
) {
  return !isShoppingCart
    ? recipients.every(({ checked }) => !checked) && !additionalRecipients.length
    : !additionalRecipients.length;
}

export function extractRecipientInformation(recipients: Recipient[]) {
  return recipients.reduce(
    (recipientInformation, { checked, id, type }) => {
      if (checked) {
        recipientInformation.idsOfRecipients.push(id);
        recipientInformation.typesOfRecipients.push(type);
      }

      return recipientInformation;
    },
    { idsOfRecipients: [] as IdsOfRecipients, typesOfRecipients: [] as TypesOfRecipients },
  );
}

export function extractIdsOfAdditionalRecipients(additionalRecipients: Option[]) {
  return additionalRecipients.map(({ value }) => (isString(value) ? parseInt(value) : value));
}

export function overwriteHistoryTab(tab: Tab['tab']) {
  return tab === 'his' ? 'job' : tab;
}
