export function Paper() {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { elevation: 1 },
              style: { boxShadow: '0px 1px 5px 1px rgba(0, 0, 0, 0.1)' },
            },
            {
              props: { elevation: 2 },
              style: { boxShadow: '0px 1px 5px 0px rgba(0, 0, 0, 0.12)' },
            },
            {
              props: { elevation: 3 },
              style: { boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.12)' },
            },
            {
              props: { elevation: 4 },
              style: { boxShadow: '0px 1px 10px 0px rgba(0, 0, 0, 0.12)' },
            },
            {
              props: { elevation: 5 },
              style: { boxShadow: '0px 1px 14px 0px rgba(0, 0, 0, 0.12)' },
            },
            {
              props: { elevation: 6 },
              style: { boxShadow: '0px 1px 18px 0px rgba(0, 0, 0, 0.12)' },
            },
            {
              props: { elevation: 7 },
              style: { boxShadow: '0px 2px 20px 1px rgba(0, 0, 0, 0.12)' },
            },
            {
              props: { elevation: 8 },
              style: { boxShadow: '0px 3px 18px 2px rgba(0, 0, 0, 0.15)' },
            },
            {
              props: { elevation: 9 },
              style: { boxShadow: '0px 3px 16px 2px rgba(0, 0, 0, 0.16)' },
            },
            {
              props: { elevation: 10 },
              style: {
                boxShadow:
                  '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 11 },
              style: {
                boxShadow:
                  '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 12 },
              style: {
                boxShadow:
                  '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 13 },
              style: {
                boxShadow:
                  '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 14 },
              style: {
                boxShadow:
                  '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 15 },
              style: {
                boxShadow:
                  '0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 16 },
              style: {
                boxShadow:
                  '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 17 },
              style: {
                boxShadow:
                  '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 18 },
              style: {
                boxShadow:
                  '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 19 },
              style: {
                boxShadow:
                  '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 20 },
              style: {
                boxShadow:
                  '0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 21 },
              style: {
                boxShadow:
                  '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 22 },
              style: {
                boxShadow:
                  '0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 23 },
              style: {
                boxShadow:
                  '0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
              },
            },
            {
              props: { elevation: 24 },
              style: {
                boxShadow:
                  '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
              },
            },
          ],
        },
      },
    },
  };
}
