import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { Container, Grid, Stack } from '@mui/material';

import { ActiveFiltersOfJobs } from 'src/components/ActiveFiltersOfJobs';
import { Backup } from 'src/components/Backup';
import { FilterDrawer } from 'src/components/FilterDrawer';
import { JobsTable } from 'src/features/JobsTable';
import { ActionBar } from 'src/features/JobsTable/components/ActionBar';
import { useJobs } from 'src/features/JobsTable/utilities/hooks';
import { Tasks } from 'src/features/Tasks';
import { useGetTeamMembersQuery } from 'src/pages/Dashboard/Dashboard.service';
import { openWaveSnack } from 'src/store/waveSnackSlice';
import { useAppDispatch } from 'src/utilities/hooks';
import { CONTAINER_STYLES, STACK_STYLES } from 'src/utilities/stylesOfJobs';

export function Dashboard() {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const {
    approvalTasks,
    areActiveFiltersVisible,
    areJobsFetching,
    columns,
    dashboardUserId,
    deadlines,
    handleChangePageSearch,
    handleToggleActiveFiltersVisibility,
    isMyBackupUserActive,
    isUserIBackupActive,
    jobTasks,
    rows,
    searchTerm,
    setAreActiveFiltersVisible,
    setDashboardUserId,
    setIsMyBackupUserActive,
    setIsUserIBackupActive,
    setPageHistory,
    statusChangeTasks,
    totalRowCount,
  } = useJobs();

  const { data: users, error, isError, isLoading: isLoadingUsers } = useGetTeamMembersQuery({}, {});

  if (isError) {
    dispatch(
      openWaveSnack({
        message: error?.message,
        type: 'error',
      }),
    );
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (location.state?.missingRights) {
      dispatch(
        openWaveSnack({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          message: `You do not have rights to ${location.state.missingRights}.`,
          type: 'error',
        }),
      );
    }
  }, [location]);

  return (
    <>
      <Container disableGutters maxWidth={false}>
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={9}>
            <Tasks
              approvalTasks={approvalTasks}
              isLoading={areJobsFetching}
              isLoadingUsers={isLoadingUsers}
              jobTasks={jobTasks}
              onSetTeammateID={setDashboardUserId}
              statusChangeTasks={statusChangeTasks}
              teammateID={dashboardUserId}
              users={users || {}}
            />
          </Grid>

          <Grid item xs={3}>
            <Backup
              myBackupUserActive={isMyBackupUserActive}
              onSetMyBackupUserActive={setIsMyBackupUserActive}
              onSetUserIBackupActive={setIsUserIBackupActive}
              userIBackupActive={isUserIBackupActive}
              users={users || {}}
            />
          </Grid>
        </Grid>
      </Container>

      <Container disableGutters maxWidth={false} sx={CONTAINER_STYLES}>
        <ActionBar
          dashboardUserId={dashboardUserId}
          onChangePageSearch={handleChangePageSearch}
          onToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          searchTerm={searchTerm}
        />

        <ActiveFiltersOfJobs
          areActiveFiltersVisible={areActiveFiltersVisible}
          setAreActiveFiltersVisible={setAreActiveFiltersVisible}
        />
      </Container>

      <Stack {...STACK_STYLES}>
        <FilterDrawer
          areActiveFiltersVisible={areActiveFiltersVisible}
          handleToggleActiveFiltersVisibility={handleToggleActiveFiltersVisibility}
          parentLocation="dashboard"
        />

        <JobsTable
          areActiveFiltersVisible={areActiveFiltersVisible}
          areJobsFetching={areJobsFetching}
          columns={columns}
          deadlines={deadlines}
          rows={rows}
          setPageHistory={setPageHistory}
          totalRowCount={totalRowCount}
        />
      </Stack>
    </>
  );
}
