import { createApi } from '@reduxjs/toolkit/query/react';

import { AxiosProgressEvent } from 'axios';

import { axiosBaseQuery } from 'src/utilities/baseQuery';
import { ExpandedAges } from 'src/utilities/hooks/useRouteParams';

type ActionBar = {
  bulk: Button[];
  single: Button[];
};
type Button = {
  code: string;
  name: string;
};
type CreateFolderRequest = RenameFoldersRequest;
type DeleteFileRequest = {
  fileId: number;
};
type DeleteFolderRequest = {
  path?: number | string;
};
export type GeneralFile = {
  can_delete: boolean;
  date: number;
  file_name: string;
  id: number;
  size: number;
  thumbnail_url: string;
  user: string;
};
export type FilesType = GeneralFile[];
type Folder = {
  code: string;
  file_count: number;
  name: string;
  path_name: string;
  rights: { edit: boolean; delete: boolean; insert: boolean };
  subItems: FoldersType;
};
export type FoldersType = Folder[];
type GetDownloadUrlRequest = {
  file_id: number[];
};
type GetFilesRequest = {
  path: string;
};
type GetFoldersRequest = {};
type RenameFoldersRequest = {
  path: string;
  name: string;
};
type UploadFileRequest = {
  file: File;
  destination: string;
} & OnUploadProgress;
export type OnUploadProgress = {
  onUploadProgress: (progressEvent: AxiosProgressEvent) => void;
};

type GetProofscopeFileRequest = {
  fileId?: number;
  jobId?: number | string;
  age?: ExpandedAges;
  jobType?: string;
};

export type GetProofScopeFileUrlType = Partial<{
  id: string;
  displayName: string;
}>;

type GetProofscopeFileResponse = {
  url: string;
  other_details?: {
    base_url: string;
    js_path: string;
    username: string;
    language: string;
    token: string;
    session: string;
    docs: Array<GetProofScopeFileUrlType>;
    url: string;
  };
};

export type UploadFileResponse = string;

export const generalFilesApi = createApi({
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    createFolder: build.mutation<{ message: string }, CreateFolderRequest>({
      invalidatesTags: ['Folders'],
      query(params) {
        return {
          method: 'POST',
          params,
          url: '/folders',
        };
      },
    }),
    deleteFile: build.mutation<{ message: string }, DeleteFileRequest>({
      query({ fileId }) {
        return {
          method: 'DELETE',
          params: { fileId },
          url: `/files/${fileId}`,
        };
      },
    }),
    deleteFolder: build.mutation<{ message: string }, DeleteFolderRequest>({
      invalidatesTags: ['Files', 'Folders'],
      query(params) {
        return {
          method: 'DELETE',
          params,
          url: '/folders',
        };
      },
    }),
    getActionBar: build.query<ActionBar, {}>({
      providesTags: ['ActionBar'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/files/actionbar',
        };
      },
    }),
    getDownloadUrl: build.query<{ url: string }, GetDownloadUrlRequest>({
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/files/download',
        };
      },
    }),
    getFiles: build.query<FilesType, GetFilesRequest>({
      providesTags: ['Files'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/files',
        };
      },
    }),
    getFolders: build.query<FoldersType, GetFoldersRequest>({
      providesTags: ['Folders'],
      query(params) {
        return {
          method: 'GET',
          params,
          url: '/folders',
        };
      },
    }),
    getProofscopeFile: build.query<GetProofscopeFileResponse, GetProofscopeFileRequest>({
      query({ age, fileId, jobId, jobType }) {
        return {
          method: 'GET',
          params: {
            age,
            file_id: fileId,
            jobid: jobId,
            src: jobType,
          },
          url: '/jobs/files/view',
        };
      },
    }),
    renameFolder: build.mutation<{ message: string }, RenameFoldersRequest>({
      invalidatesTags: ['Folders'],
      query(params) {
        return {
          method: 'PUT',
          params,
          url: '/folders',
        };
      },
    }),
    uploadFile: build.mutation<UploadFileResponse, UploadFileRequest>({
      invalidatesTags: (result, error) => (error ? [] : ['Files', 'Folders']),
      query({ destination, file, onUploadProgress }) {
        const formData = new FormData();

        formData.append('file', file);
        formData.append('path', destination);

        return {
          data: formData,
          formData: true,
          method: 'POST',
          onUploadProgress,
          url: '/files',
        };
      },
    }),
  }),
  reducerPath: 'generalFilesApi',
  tagTypes: ['ActionBar', 'Files', 'Folders'],
});

export const {
  useCreateFolderMutation,
  useDeleteFileMutation,
  useDeleteFolderMutation,
  useGetActionBarQuery,
  useGetFilesQuery,
  useGetFoldersQuery,
  useGetProofscopeFileQuery,
  useLazyGetDownloadUrlQuery,
  useRenameFolderMutation,
  useUploadFileMutation,
} = generalFilesApi;
