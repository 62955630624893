import { Grid, Skeleton, useTheme } from '@mui/material';
import { BarChart as MuiBarChart } from '@mui/x-charts';

import {
  StatusChangeTask,
  StatusChangeTasks,
} from 'src/features/JobsTable/utilities/hooks/useJobs';
import { ChartTitle } from '../ChartTitle';
import { ChartTooltip } from '../ChartTooltip';
import { NothingToShow } from '../NothingToShow';

type BarChartProps = {
  bars: StatusChangeTasks;
  hoverBarLabel: string;
  isLoading: boolean;
  title: string;
};

export function BarChart({ bars, isLoading, title }: BarChartProps) {
  const theme = useTheme();

  let total = 0;

  bars.forEach((bar: StatusChangeTask) => {
    total += bar.total;
  });

  if (isLoading) {
    return (
      <Grid>
        <Skeleton height={100} variant="rectangular" />
      </Grid>
    );
  }

  if (!bars.length) {
    return <NothingToShow title={title} />;
  }

  return (
    <Grid alignItems="center" container direction="column" item wrap="nowrap">
      <ChartTitle title={`${title}: ${total}`} />

      <Grid item>
        <MuiBarChart
          barLabel="value"
          grid={{ horizontal: true, vertical: true }}
          height={150}
          margin={{ bottom: 20, left: 20, right: 0, top: 10 }}
          series={[
            {
              color: theme.palette.primary.main,
              data: bars.map((bar) => bar.total),
            },
          ]}
          sx={{
            '& .MuiBarLabel-root': {
              fill: '#fff !important',
            },
            '& .MuiChartsAxis-line': {
              stroke: 'rgba(0, 0, 0, 0.12) !important',
            },
            '& .MuiChartsAxis-tick': {
              stroke: 'rgba(0, 0, 0, 0.12) !important',
            },
          }}
          tooltip={{
            itemContent: (props) => <ChartTooltip items={bars} {...props} />,
            trigger: 'item',
          }}
          width={300}
          xAxis={[
            {
              data: bars.map((bar) => bar.label),
              scaleType: 'band',
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
