import dayjs from 'dayjs';

import { Box, Skeleton, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';

import { useJobHeader } from 'src/components/JobHeader/useJobHeader';
import {
  BACKEND_DATE_FORMAT,
  LONG_DATE_FORMAT,
} from 'src/components/RHF/DateField/utilities/constants';
import { isZeroFormatDate } from 'src/components/RHF/DateField/utilities/helperFunctions';
import { Thumbnail } from 'src/components/Thumbnail';
import { WaveTooltip } from 'src/components/WaveTooltip';
import { WaveIcon } from 'src/features/WaveIcon';
import { FieldValues } from 'src/pages/Job/Job.service';
import { isString } from 'src/utilities/helperFunctions2';
import { useDateFormat, withFullScreen } from 'src/utilities/hooks';

export function JobHeader() {
  const {
    flags,
    handleClickThumbnailContainer,
    headerLayout,
    isFetching,
    jobFields,
    jobValues,
    preview,
    tasks,
  } = useJobHeader();
  const { formatDate } = useDateFormat();

  const standardColumnGridProps = {
    lg: true,
    sx: {
      bgcolor: isFetching ? '' : 'filler.main',
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '100%',
      p: isFetching ? 0 : 1,
    },
    xs: isFetching ? true : ('auto' as const),
  };

  function renderFieldColumns() {
    return Object.keys(headerLayout).map((columnKey) => {
      const fields = headerLayout[columnKey as keyof typeof headerLayout];

      return fields ? (
        <Grid2 key={columnKey} {...standardColumnGridProps}>
          {isFetching ? (
            <Skeleton sx={{ height: 112 }} variant="rounded" />
          ) : (
            fields.map((fieldAlias) => {
              const fieldName = jobFields[fieldAlias]?.name;
              const fieldValue = (jobValues as FieldValues)[fieldAlias];
              const isDate =
                dayjs(fieldValue?.toString(), 'YYYY-MM-DD HH:mm:ss', true).isValid() ||
                dayjs(fieldValue?.toString(), BACKEND_DATE_FORMAT, true).isValid() ||
                (isString(fieldValue) && isZeroFormatDate(fieldValue));

              const formattedValue = isDate
                ? formatDate(LONG_DATE_FORMAT, fieldValue as string)
                : fieldValue;

              return fieldName ? (
                <Typography key={fieldAlias}>
                  <Typography component="span" sx={{ fontWeight: 700, mr: 1 }}>
                    {fieldName}:
                  </Typography>

                  {formattedValue}
                </Typography>
              ) : (
                <Typography key={fieldAlias} sx={{ color: 'error.main' }}>
                  {fieldAlias}
                </Typography>
              );
            })
          )}
        </Grid2>
      ) : null;
    });
  }

  return (
    <Grid2 container sx={{ flexWrap: 'wrap', gap: 3 }}>
      {preview?.url !== undefined && preview?.url !== '' ? (
        <Grid2 onClick={handleClickThumbnailContainer} sx={{ cursor: 'pointer' }} xs="auto">
          <Thumbnail
            alt="latest artwork thumbnail"
            hasExternalLink
            size={112}
            url={preview?.url ?? ''}
          />
        </Grid2>
      ) : null}

      {renderFieldColumns()}

      {!isFetching && flags.length ? (
        <Grid2 {...standardColumnGridProps}>
          {flags.length
            ? flags.map(({ code, comment, name, reason }) => (
                <WaveTooltip
                  body={comment}
                  component={
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <WaveIcon
                        code={`job-header-flag-${code}`}
                        sx={{ color: code === 'onhold' ? 'warning.main' : 'error.main' }}
                      />

                      <Typography sx={{ fontWeight: 700 }}>{name}</Typography>
                    </Box>
                  }
                  header={reason}
                  key={name}
                  placement="left"
                  type="simple"
                />
              ))
            : null}
        </Grid2>
      ) : null}

      {!isFetching && tasks.length ? (
        <Grid2 {...standardColumnGridProps}>
          {tasks.map(({ name, value }) => (
            <Box key={name} sx={{ display: 'flex', gap: 1 }}>
              <WaveIcon code={`job-header-task-${value}`} />

              <Typography sx={{ fontWeight: 700 }}>{name}</Typography>
            </Box>
          ))}
        </Grid2>
      ) : null}
    </Grid2>
  );
}

export const VisibilityJobHeader = withFullScreen(JobHeader, {
  hideInFullScreen: true,
  hideInSemiFullScreen: true,
});
